import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/reducers/authReducer';
import pageTitleReducer from '../features/reducers/pageTitleReducer';
import updateProfileReducer from '../features/reducers/updateProfileReducer';
import breadcrumbsReducer from "../features/reducers/breadcrumbsReducer";


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    title:pageTitleReducer,
    updateProfile: updateProfileReducer,
    breadcrumbs: breadcrumbsReducer

  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
