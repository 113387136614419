const initialState = {
    isPageTitle: false,
    user: null,
    error: null,
  };

  const pageTitleReducer = (state = initialState, action:any) => {
    switch (action.type) {
      case 'PAGE_TITLE':
        return {
          ...state,
          isPageTitle: action.payload,
          user: null,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default pageTitleReducer;