import React from 'react'
import LeftMenu from './LeftMenu'

const AdminDashboard = ({ currentuser })=> {
  return (
    <>    
    {currentuser && ( <LeftMenu  currentuser={currentuser}/>)}
    </>
  )
}

export default AdminDashboard