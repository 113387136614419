
import API from '../AxiosConfig';

class CustomUploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.url = '/admin/uploadsImage'; 
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('image', file);

        const token = localStorage.getItem('token'); 

        API.post(this.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`, 
          },
        })
        .then(response => {
          resolve({
            default: response.data.profilePicUrl,
          });
        })
        .catch(error => {
          reject(error);
        });
      }));
  }

  abort() {
  }
}

export function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new CustomUploadAdapter(loader);
  };
}
