import { io } from 'socket.io-client';
let URLs;
if(process.env.REACT_APP_ENV === 'local')
{
 URLs = process.env.REACT_APP_DEV_SERVER_URL;
}
else if(process.env.REACT_APP_ENV == 'dev')
{
 URLs = process.env.REACT_APP_DEV_SERVER_URL;
}
else if(process.env.REACT_APP_ENV == 'production')
    {
     URLs = process.env.REACT_APP_DEV_SERVER_URL;
    }
let socket;
export default socket = io(URLs);