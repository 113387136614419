// breadcrumbsReducer.js
import { SET_BREADCRUMBS } from "../actions/pageBreadCrumps";

const initialState = {
  breadcrumbs: [],
};

const breadcrumbsReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    default:
      return state;
  }
};

export default breadcrumbsReducer;
