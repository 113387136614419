import React, { useEffect, useState } from 'react';
import API from '../../../AxiosConfig';
import { Bars } from 'react-loader-spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux/es/hooks/useSelector';


const MeetingForm = ({ projectid }) => {
  const [date, setDate] = useState(null);
  const [text, setText] = useState('');
  const [meetingLogs, setMeetingLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const userData = useSelector(state => state.auth.user);
  const ActiveuserId = userData?.user?.id;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Clear previous errors
    setFormErrors([]);

    // Validation logic
    const errors = [];
    if (!date) errors.push('Date is required.');
    if (!text.trim()) errors.push('Meeting notes are required.');

    if (errors.length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return; // Stop form submission
    }

    try {
      const formattedDate = date ? `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}` : '';
      const response = await API.post('/admin/saveMeeting/' + projectid, { date: formattedDate, text, projectid, addedUser : ActiveuserId });
      console.log(response.data);
      setLoading(false);
      setDate(null);
      setText('');
      getLogsDetails();
    } catch (error) {
      setLoading(false);
      console.error('There was an error saving the meeting!', error);
    }
  };

  const getLogsDetails = async () => {
    setLoading(true);
    try {
      const response = await API.get('/admin/saveMeeting/' + projectid);
      if (response.status === 200) {
        setLoading(false);
        console.log(response.data);
        setMeetingLogs(response.data);
      } else {
        setLoading(false);
        console.error('Error while getting the data');
      }
    } catch (error) {
      setLoading(false);
      console.error('There was an error fetching the meeting logs!', error);
    }
  };

  useEffect(() => {
    getLogsDetails();
  }, [projectid]);

  return (
    <div className="meeting-now">
      {loading && (
        <div className='rotate-line-loader'>
          <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
        </div>
      )}
      
      <form onSubmit={handleSubmit}>
        {formErrors.length > 0 && (
          <div className="form-errors">
            {formErrors.map((error, index) => (
              <p key={index} style={{ color: 'red' }}>{error}</p>
            ))}
          </div>
        )}
        
        <div className="calender-box">
          <div className="calender-input">
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="MM/dd/yyyy"
              placeholderText="mm/dd/yyyy"
              customInput={
                <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                  {(inputProps) => <input {...inputProps} />}
                </InputMask>
              }
              required
            />
          </div>
        </div>
        <div className="text-box">
          <CKEditor
            editor={ClassicEditor}
            data={text}
            config={{
              fontColor: {
                colors: [
                  {
                    color: 'hsl(0, 0%, 0%)',
                    label: 'Black'
                  },
                ]
              },
              toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
            }}
            onReady={editor => {
                console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                setText(data);
            }}
          />
        </div>
        <div className="button-wrapper save">
          <button type="submit" className="primaryBtn">
            Save
          </button>
        </div>
      </form>
      <h3 className='logs-heading'>Meeting Logs</h3>
      {meetingLogs.map((log, index) => (
        <div className='sr-cstm-btn all-acitivty-log' key={index}>
          {log.meetingNotes?.map((note, noteIndex) => (
            <p className='logo-act' key={noteIndex} dangerouslySetInnerHTML={{ __html: `${note.text} Date: ${note.date}` }} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MeetingForm;
