export function getFileDisplaydefault(file){
    const isRemoteFile = typeof file === 'string';
    const fileName = isRemoteFile ? decodeURIComponent(file.split('/').pop().split('?')[0]) : file.name;
    
    // Build the display logic based on file extension
    if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src={file} alt={fileName} style={{ width: '100px', maxHeight: '200px' }} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(zip)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/zip.png" alt={fileName} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(pdf)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/pdf.png" alt={fileName} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(doc|docx)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/doc.png" alt={fileName} />
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/document.png" alt={fileName} />
          </a>
        </div>
      );
    }
  };


  export function getFileDisplay(file){
    console.log("getFileDisplay", file);
 
    const isRemoteFile = typeof file === 'string';
    const fileName = isRemoteFile ? decodeURIComponent(file.split('/').pop().split('?')[0]) : file?.originalname;
    console.log("fileName", fileName);
    if (fileName?.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return (
        <div>
          <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
            <img src={file.fileUrl} alt={fileName} style={{ width: '100px', maxHeight: '200px' }} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(zip)$/i)) {
      return (
        <div>
          <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/zip.png" alt={fileName} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(pdf)$/i)) {
      return (
        <div>
          <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/pdf.png" alt={fileName} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(doc|docx)$/i)) {
      return (
        <div>
          <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/doc.png" alt={fileName} />
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/document.png" alt={fileName} />
          </a>
        </div>
      );
    }
  };

  export function getFileDisplaySearch(file){
    const isRemoteFile = typeof file === 'string';
    const fileName = isRemoteFile ? decodeURIComponent(file.split('/').pop().split('?')[0]) : file;
    
    // Build the display logic based on file extension
    if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src={file} alt={fileName} style={{ width: '100px', maxHeight: '200px' }} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(zip)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/zip.png" alt={fileName} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(pdf)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/pdf.png" alt={fileName} />
          </a>
        </div>
      );
    } else if (fileName.match(/\.(doc|docx)$/i)) {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/doc.png" alt={fileName} />
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/document.png" alt={fileName} />
          </a>
        </div>
      );
    }
  };