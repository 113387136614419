import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import AddNewCompany from './AddNewCompanyModel';
import AddTeamMember from './AddTeamMember';
import ConvertOpportunityModal from './ConvertOpportunityModal';
import SetFollowUpDateModal from './SetFollowUpDateModal';
import ActivityLogModal from './ActivityLogModal';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileUpload from '../FileUpload/FileUpload';
import { Bars } from 'react-loader-spinner';
import DeleteModal from './deleteProjectModal';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 
import { useParams, Link ,useNavigate ,useLocation} from 'react-router-dom'


function Details() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [opportunity, setOpportunity] = useState({});
    const [servicesInterested, setService] = useState([]);
    const [opportunityName, setOpportunityName] = useState('');
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [opportunityAmount, setOpportunityAmount] = useState('');
    const [opportunityStatus, setOpportunityStatus] = useState('');
    const [notes, setNotes] = useState();
    const [storage, setStorage] = useState();
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFollowModal, setShowFollowModal] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [Image, setImage] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [FileDiplay, setFileDiplay] = useState('');
    const [TeamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [users, setUsers] = useState([]);  // Ensure it's an empty array by default
    const fetchAllUsers = async () => {
        try {
            const response = await API.get('/admin/users');
            console.log("API Response:", response);
    
            // Ensure response.data is an array and exists
            if (Array.isArray(response.data)) {
                const usersList = response.data.map(user => ({
                    id: user.id,
                    name: `${user.firstName} ${user.lastName}` // Combine firstName and lastName
                }));
                setUsers(usersList); // Set the transformed user data
            } else {
                console.error("Unexpected response format:", response.data);
                setUsers([]); // Set users to an empty array in case of an error
            }
        } catch (e) {
            console.error("Error fetching users:", e);
        }
    };
    


    const navigate = useNavigate();


    const refreshState = () => {
        getOppurtunityDetail();
        getNotes();
        getLogsDetails();
      };

    useEffect(() => {
        getOppurtunityDetail();
        getNotes();
        refreshState();
        fetchAllUsers();
    }, [])

    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [removeTeamMembers, setRemoveTeamMembers] = useState([]);
    const handleSelectTeamMembers = (selectedMembers) => {
        console.log('selectedMembers',selectedMembers)
        setSelectedTeamMembers(selectedMembers);
    };
    const handleRemoveTeamMembers = (userEmail) => {
        let updatedArray = selectedTeamMembers.filter(item => item.value !== userEmail);
        setSelectedTeamMembers(updatedArray)
    };
    const [errors, setErrors] = useState({
        opportunityName: '',
        opportunityStatus: '',
        opportunityAmount: '',
        content: '',
    });
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        
        // Format date as MM/DD/YYYY
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
    
        // Format time to 12-hour format with AM/PM
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const isAM = hours < 12;
        
        // Convert 24-hour time to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // '0' should be '12' for AM
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${isAM ? 'AM' : 'PM'}`;
    
        return `${formattedDate} ${formattedTime}`;
    };
    

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setService([...servicesInterested, value]);

        } else {
            setService(servicesInterested.filter(item => item !== value));
        }
    };
 

    function changeHandler(e) {
        if (e.target.name === 'image') {
            setImage({ [e.target.name]: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) });
        }
    }
    const [editorData, setEditorData] = useState("");
    // const handleEditorChange = (event, editor) => {
    //     const data = editor.getData();
    //     setEditorData(data);
    //     console.log(data);
    // }

  const [meetingLogs, setMeetingLogs] = useState([])
    const getLogsDetails = async () => {
    try {
      const response = await API.get(`/admin/getMeetingLogs/${id}`);
      if (response.status === 200) {
        setLoading(false);
        setMeetingLogs(response.data);
        console.log('Meeting notes',response.data);
      } else {
        console.error('Error while getting the data');
      }
    } catch (error) {
      console.error('There was an error fetching the meeting logs!', error);
    }
  };
 useEffect(() => {
    getLogsDetails();
   
  }, []);


    const getOppurtunityDetail = () => {
        setLoading(true);

        API.get('admin/detail/' + id + '')
            .then((response) => {
                if (response.status === 200) {
                    console.log("response", response);
                    setLoading(false);
                    setFileDiplay(response.data.fileURL);
                    setOpportunity(response.data.data);
                    setService(response.data.service);
                    setTeamMembers(response.data.matchingUsers)
                    setOpportunityName(response.data.data.opportunityName);
                    setContactName(response.data.data.contactName);
                    setPhoneNumber(response.data.data.phoneNumber);
                    setEmail(response.data.data.email);
                    setOpportunityAmount(response.data.data.opportunityAmount);
                    setOpportunityStatus(response.data.data.opportunityStatus); // Set opportunityStatus from data
                    dispatch(setBreadcrumbs([
                        { label: "Home", path: "/" },
                        { label: "Opportunities", path: "/opportunities" },
                        { label: response.data.data.opportunityName, path: `/opportunities/details/ ${response.data.data.id}`, active: true }
                    ]));
                    dispatch(pageTitle(response.data.data.opportunityName));
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };


    const getNotes = () => {
        setLoading(true);

        API.get('admin/notes/' + id + '')
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    // console.log(response.data.data);
                    setNotes(response.data.data);
                    setStorage(response.data.storage);
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    }
    const getFile = (e) => {
        e.preventDefault();
        const keyName = e.target.value;
        setLoading(true);
        API.get('admin/file/' + keyName + '')
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.setAttribute('download', keyName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(response.data);
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'opportunityName':
                setOpportunityName(value);
                break;
            case 'contactName':
                setContactName(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'opportunityAmount':
                setOpportunityAmount(value);
                break;
            case 'opportunityStatus':
                setOpportunityStatus(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};
        
        // Validate form fields
        if (!opportunityName.trim()) {
            newErrors.opportunityName = 'Opportunity Name is required';
        }
    
        if (!opportunityStatus.trim()) {
            newErrors.opportunityStatus = 'Please Select Opportunity Status';
        }
    
        if (!opportunityAmount.trim()) {
            newErrors.opportunityAmount = 'Please Select Opportunity Amount';
        }
    
        if (servicesInterested.length === 0) {
            newErrors.servicesInterested = "Please Select at least one option";
        }
    
        setErrors(newErrors);
    
        // If there are no errors, proceed with submission
        if (Object.keys(newErrors).length === 0) {
            const token = localStorage.getItem('token');
            const userInvited = selectedTeamMembers;
            const removeUsers = removeTeamMembers;
            const formData = new FormData();
            formData.append('opportunityName', opportunityName);
            formData.append('opportunityAmount', opportunityAmount);
            formData.append('opportunityStatus', opportunityStatus);
            formData.append('Image', Image); 
            formData.append('editorData', editorData);
            formData.append('token', token);
            formData.append('servicesInterested', servicesInterested);
            formData.append('userInvited', JSON.stringify(userInvited)); // Append as JSON string
            formData.append('removeUsers', JSON.stringify(removeUsers)); // Append as JSON string
            files.forEach((file) => {
                formData.append('media', file);  // Append each file
            });
            setLoading(true);
    
            // Make the API call
            API.post(`admin/updateDetails/${id}/${ActiveuserId}`, formData, userInvited, removeUsers)
                .then((response) => {
                    setLoading(false); // Reset loading state
                    
                    if (response.status === 200) {
                        setLoading(false);
                        setEditorData('');
                        getNotes();
                        getOppurtunityDetail();
                        setSelectedTeamMembers('');
                        setFiles([]); // Clear the files array here
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        toast.success("Details Updated ", {
                            position: "top-center"
                        });
                    } else {
                        toast.error('Error while getting the data', {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false); // Reset loading state on error
                    console.error(error);
                    toast.error('An error occurred while updating details', {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };
    

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleShowModalFollow = () => {
        setShowFollowModal(true);
    };

    const handleShowLogModal = () => {
        setShowLogModal(true);
    };

    const handleShowModalDelete = () => {
        setShowDeleteModal(true);
      };

    const handleRemove = async (memberId) => {
        try {
             const updatedTeamMembers = TeamMembers.filter(member => member.email !== memberId);
             setTeamMembers(updatedTeamMembers);
            setRemoveTeamMembers(prevMembers => [...prevMembers, memberId]);
        } catch (error) {
            console.error("Error removing member:", error);
        }
    };

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        };

        const getFileDisplay = (file) => {
            // Handle remote URLs
            const isRemoteFile = typeof file === 'string';
            const fileName = isRemoteFile ? decodeURIComponent(file.split('/').pop().split('?')[0]) : file.name;
            
            // Build the display logic based on file extension
            if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
              return (
                <div>
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <img src={file} alt={fileName} style={{ maxWidth: '200px', maxHeight: '200px' }} />
                  </a>
                 
                </div>
              );
            } else if (fileName.match(/\.(zip)$/i)) {
              return (
                <div>
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/zip.png" alt={fileName} />
                  </a>
                </div>
              );
            } else if (fileName.match(/\.(pdf)$/i)) {
              return (
                <div>
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/pdf.png" alt={fileName} />
                  </a>
                </div>
              );
            } else if (fileName.match(/\.(doc|docx)$/i)) {
              return (
                <div>
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/doc.png" alt={fileName} />
                  </a>
                </div>
              );
            } else {
              return (
                <div>
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/document.png" alt={fileName} />
                  </a>
                </div>
              );
            }
          };

          const handleFileChange = (e) => {
            const selectedFiles = Array.from(e.target.files);
            setFiles([...files, ...selectedFiles]);
        };
        
        const [mentionSearch, setMentionSearch] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const editorRef = useRef(null);
    const tooltipRef = useRef(null);

        const handleEditorChange = (event, editor) => {
            const data = editor.getData();
            setEditorData(data);
            const textContent = editor.getData().replace(/<\/?[^>]+(>|$)/g, "").trim();
            const lastChar = textContent.charAt(textContent.length - 1);
            if (lastChar === '@') {
                console.log("Found '@' symbol");  // Log when '@' is found
                setShowTooltip(true);
            } else if (lastChar === ' ' || lastChar === '<' || lastChar === '>') {
                setShowTooltip(false);
            }
        
            // Filter users based on what is typed after '@'
            const mentionText = textContent.split('@')[1];
            if (mentionText) {
                setMentionSearch(mentionText);
                const filtered = users.filter(user =>
                    user.name.toLowerCase().includes(mentionText.toLowerCase())
                );
                setFilteredUsers(filtered);
            }
        };
        

    // Handle the insertion of a mention
    const insertMention = (user) => {
        const editor = editorRef.current.editor;
        const data = editor.getData();
        const newData = data.replace(`@${mentionSearch}`, `@${user.name}`);
        editor.setData(newData);
        setShowTooltip(false);
    };

    useEffect(() => {
        // Scroll the tooltip into view when it is displayed
        if (showTooltip && tooltipRef.current) {
            tooltipRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [showTooltip]);
          
          

    return (
        <div className="main-dashboard-admin">
                        <div className='cient-announcement team-header-info'>

            {opportunity?.content && (
                    <div className='announcement-bar'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="Icon_material-speaker-notes" data-name="Icon material-speaker-notes" d="M17.4,3H4.6A1.6,1.6,0,0,0,3.008,4.6L3,19l3.2-3.2H17.4A1.6,1.6,0,0,0,19,14.2V4.6A1.6,1.6,0,0,0,17.4,3ZM7.8,12.6H6.2V11H7.8Zm0-2.4H6.2V8.6H7.8Zm0-2.4H6.2V6.2H7.8Zm5.6,4.8h-4V11h4Zm2.4-2.4H9.4V8.6h6.4Zm0-2.4H9.4V6.2h6.4Z" transform="translate(-3 -3)" />
                        </svg>
                        <span>
                            {opportunity.content}
                        </span>
                    </div>
                )}
                </div>
                <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>

            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}

             {/* <div class="breadcrumbs">
                <Link to={"/"}>Home </Link><span><i class="fa fa-chevron-right"></i> 
                <Link className='sr-active-bread' to={"/opportunities"} > Opportunities </Link></span>
            </div> */}
             {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            
            <div className="addNewOppFields opp-details-iner">
            {userData?.user?.roleId === 1 ? (
                <div className="fieldsBlock">
                    <div className="field ">
                        <label htmlFor="">Opportunity Company Name<span className='' style={{ color: 'red' }}>*</span></label>
                        <input type='text' disabled value={opportunity.companyName} ></input>
                    </div>
                    <div className="field opportunitiesName">
                        <label htmlFor="">Opportunity Name</label>
                        <input
                            type="text"
                            placeholder=""
                            name='opportunityName'
                            value={opportunityName}
                            onChange={handleChange}
                        />
                        {errors.opportunityName && <span style={{ color: 'red' }}>{errors.opportunityName}</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="">Opportunity Status<span className='' style={{ color: 'red' }}>*</span></label>
                        <div className="selectDropdown">
                            <select name="opportunityStatus" id="types" onChange={handleChange} value={opportunityStatus}>
                            <option value="">Please Select</option>
                                    <option value="Initial Discussions">Initial Discussions</option>
                                    <option value="Awaiting Proposal">Awaiting Proposal</option>
                                    <option value="Proposal Sent">Proposal Sent</option>
                                    <option value="Project Lost">Project Lost</option>
                                    <option value="Project Won">Project Won</option>
                            </select>
                            <div className="dropIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988"
                                    viewBox="0 0 13.971 7.988">
                                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward"
                                        d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                                        transform="translate(20.168 -11.246) rotate(90)" />
                                </svg>
                            </div>
                            {errors.opportunityStatus && <span style={{ color: 'red' }}>{errors.opportunityStatus}</span>}
                        </div>
                    </div>

                
                    <div className="field">
                        <label htmlFor="">Opportunity Amount</label>
                        <div className='dollarWrap'>
                            <div className='dollarSign'>$</div>
                            <input
                                type="number"
                                placeholder=""
                                name='opportunityAmount'
                                value={opportunityAmount}
                                onChange={handleChange}
                            />
                            {errors.opportunityAmount && <span style={{ color: 'red' }}>{errors.opportunityAmount}</span>}
                        </div>
                    </div>

                    <div className="field checkBoxOptions ">
                        <label htmlFor="">Services Interested In: <p><span onClick={handleShowModalFollow}><svg xmlns="http://www.w3.org/2000/svg"
                            width="14.718" height="16.82" viewBox="0 0 14.718 16.82">
                            <path id="Icon_awesome-calendar-check" data-name="Icon awesome-calendar-check"
                                d="M14.324,5.256H.394A.394.394,0,0,1,0,4.862V3.679A1.577,1.577,0,0,1,1.577,2.1H3.154V.394A.394.394,0,0,1,3.548,0H4.862a.394.394,0,0,1,.394.394V2.1H9.461V.394A.394.394,0,0,1,9.856,0H11.17a.394.394,0,0,1,.394.394V2.1h1.577a1.577,1.577,0,0,1,1.577,1.577V4.862A.394.394,0,0,1,14.324,5.256ZM.394,6.308H14.324a.394.394,0,0,1,.394.394v8.542a1.577,1.577,0,0,1-1.577,1.577H1.577A1.577,1.577,0,0,1,0,15.243V6.7A.394.394,0,0,1,.394,6.308ZM11.344,9.46l-.925-.933a.394.394,0,0,0-.558,0L6.377,11.98,4.867,10.457a.394.394,0,0,0-.558,0l-.933.925a.394.394,0,0,0,0,.558l2.714,2.736a.394.394,0,0,0,.558,0l4.7-4.659a.394.394,0,0,0,0-.558Z"
                                fill="#083d78" />
                        </svg>

                        Follow Up Date:</span>{opportunity?.followUpDate ? formatDate(opportunity.followUpDate) : "N/A"}</p></label>
                            <div className="ServicesInterested">
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Development'
                                    checked={servicesInterested.includes('Development')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Development</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Design'
                                    checked={servicesInterested.includes('Design')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Design</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Automation'
                                    checked={servicesInterested.includes('Automation')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Automation</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='SEO'
                                    checked={servicesInterested.includes('SEO')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>SEO</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='PPC'
                                    checked={servicesInterested.includes('PPC')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>PPC</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Social'
                                    checked={servicesInterested.includes('Social')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Social Media</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Email'
                                    checked={servicesInterested.includes('Email')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Email</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Reputation'
                                    checked={servicesInterested.includes('Reputation')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Reputation</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Other'
                                    checked={servicesInterested.includes('Other')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Other</span>
                            </div>
                        </div>
                        {errors.servicesInterested && <span style={{ color: 'red' }}>{errors.servicesInterested}</span>}
                    </div>
                </div>
                ): 
                <div className="fieldsBlock">
                    <div className="field ">
                        <label htmlFor="">Opportunity Company Name<span className='' style={{ color: 'red' }}>*</span></label>
                        <input type='text' disabled value={opportunity.companyName} ></input>
                    </div>
                    <div className="field opportunitiesName">
                        <label htmlFor="">Opportunity Name</label>
                        <input
                            type="text"
                            placeholder=""
                            name='opportunityName'
                            value={opportunityName}
                            onChange={handleChange}
                            disabled
                        />
                        {errors.opportunityName && <span style={{ color: 'red' }}>{errors.opportunityName}</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="">Opportunity Status<span className='' style={{ color: 'red' }}>*</span></label>
                        <div className="selectDropdown">
                            <select name="opportunityStatus" id="types" disabled onChange={handleChange} value={opportunityStatus}>
                            <option value="">Please Select</option>
                                    <option value="Initial Discussions">Initial Discussions</option>
                                    <option value="Awaiting Proposal">Awaiting Proposal</option>
                                    <option value="Proposal Sent">Proposal Sent</option>
                                    <option value="Project Lost">Project Lost</option>
                                    <option value="Project Won">Project Won</option>
                            </select>
                            <div className="dropIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988"
                                    viewBox="0 0 13.971 7.988">
                                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward"
                                        d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                                        transform="translate(20.168 -11.246) rotate(90)" />
                                </svg>
                            </div>
                            {errors.opportunityStatus && <span style={{ color: 'red' }}>{errors.opportunityStatus}</span>}
                        </div>
                    </div>

                
                    <div className="field">
                        <label htmlFor="">Opportunity Amount</label>
                        <div className='dollarWrap'>
                            <div className='dollarSign'>$</div>
                            <input
                                type="number"
                                placeholder=""
                                name='opportunityAmount'
                                value={opportunityAmount}
                                onChange={handleChange}
                                disabled
                            />
                            {errors.opportunityAmount && <span style={{ color: 'red' }}>{errors.opportunityAmount}</span>}
                        </div>
                    </div>

                    <div className="field checkBoxOptions ">
                        <label htmlFor="">Services Interested In: <p><span><svg xmlns="http://www.w3.org/2000/svg"
                            width="14.718" height="16.82" viewBox="0 0 14.718 16.82">
                            <path id="Icon_awesome-calendar-check" data-name="Icon awesome-calendar-check"
                                d="M14.324,5.256H.394A.394.394,0,0,1,0,4.862V3.679A1.577,1.577,0,0,1,1.577,2.1H3.154V.394A.394.394,0,0,1,3.548,0H4.862a.394.394,0,0,1,.394.394V2.1H9.461V.394A.394.394,0,0,1,9.856,0H11.17a.394.394,0,0,1,.394.394V2.1h1.577a1.577,1.577,0,0,1,1.577,1.577V4.862A.394.394,0,0,1,14.324,5.256ZM.394,6.308H14.324a.394.394,0,0,1,.394.394v8.542a1.577,1.577,0,0,1-1.577,1.577H1.577A1.577,1.577,0,0,1,0,15.243V6.7A.394.394,0,0,1,.394,6.308ZM11.344,9.46l-.925-.933a.394.394,0,0,0-.558,0L6.377,11.98,4.867,10.457a.394.394,0,0,0-.558,0l-.933.925a.394.394,0,0,0,0,.558l2.714,2.736a.394.394,0,0,0,.558,0l4.7-4.659a.394.394,0,0,0,0-.558Z"
                                fill="#083d78" />
                        </svg>

                        Follow Up Date:</span>{opportunity?.followUpDate ? formatDate(opportunity.followUpDate) : "N/A"}</p></label>
                            <div className="ServicesInterested">
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Development'
                                    checked={servicesInterested.includes('Development')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>Development</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Design'
                                    checked={servicesInterested.includes('Design')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>Design</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Automation'
                                    checked={servicesInterested.includes('Automation')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>Automation</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='SEO'
                                    checked={servicesInterested.includes('SEO')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>SEO</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='PPC'
                                    checked={servicesInterested.includes('PPC')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>PPC</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Social Media'
                                    checked={servicesInterested.includes('Social Media')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>Social Media</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Email Marketing Reputation'
                                    checked={servicesInterested.includes('Email Marketing Reputation')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>Email Marketing Reputation</span>
                            </div>
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Other'
                                    checked={servicesInterested.includes('Other')}
                                    onChange={handleCheckboxChange}
                                    disabled
                                />
                                <span>Other</span>
                            </div>
                        </div>
                        {errors.servicesInterested && <span style={{ color: 'red' }}>{errors.servicesInterested}</span>}
                    </div>
                </div>
                }
                <div className="postedNotesBlock">
                    <h4>Posted Notes</h4>
                        {notes && notes.map((items) => (
                            <div className="commentedNotes">
                                {/* <div className=""> */}
                                    {/* <div className="personImage"> */}
                                        {/* <img src="" alt="" /> */}
                                    {/* </div> */}
                                {/* </div> */}
                                <div className="commentBox">
                                    <div className='sr-cs-wrap'>
                                    <h4>{items.user.firstName} {items.user.lastName}</h4>  <span>{formatDateTime(items.createdAt)}</span>
                                    </div>
                                    <p> <div dangerouslySetInnerHTML={{ __html: items.notes }} /></p>
                                    {items && items?.fileUrl ? getFileDisplay(items.fileUrl) : ''}
                                    <p>{items.originalName}</p>

                                    
                                    {/* {items.media && JSON.parse(items.media).map((file, index) => (
                                        <div>
                                            <img src={`${items.mediaPath}/${items.project.opportunityName}/${file}`} alt="Media" width="500px" height="300px" />
                                            {file.type === 'document' && <a href target="_blank" rel="noopener noreferrer">Download Document</a>}
                                        </div>
                                    ))} */}

                                    
                                    </div>
                            </div>
                        ))}
                    {
                        storage && storage.map((items) => (
                            <button type='button' name="storage_key" value={items.storageKey} onClick={getFile}>
                                {items.storageKey}
                            </button>
                        ))
                    }
                    {meetingLogs.map((log, index) => (
  <div key={index} className='sr-cstm-btn all-acitivty-log'>
    {/* Display meeting notes */}
    {log.meetingNotes?.map((note, noteIndex) => (
      <p className='logo-act' key={noteIndex} dangerouslySetInnerHTML={{
        __html: `
          <div class='sr-head-wrap-notes'>
            <h4> ${note.addedUserDetails ? `${note.addedUserDetails.firstName} ${note.addedUserDetails.lastName} added ` : '' }Meeting Note: ${note.date}</h4> 
        <p class='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div class='sr-head-wrap-notes-2'>${note.text}</div>
         
        ` }} />
    ))}
    {/* Display in-person meetings */}
    {log.inPersonMeeting?.map((inPerson, inPersonIndex) => (
      <p className='logo-act' key={inPersonIndex} dangerouslySetInnerHTML={{
        __html: `
          <div class='sr-head-wrap-notes'>
                      <h4> ${inPerson.addedUserDetails ? `${inPerson.addedUserDetails.firstName} ${inPerson.addedUserDetails.lastName} added ` : '' }In Person Meeting Note: ${inPerson.date}</h4> 
        <p class='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div class='sr-head-wrap-notes-2'>${inPerson.text}</div>
       
        ` }} />
    ))}
    {/* Display virtual meetings */}
    {log.virtualMeeting?.map((virtual, virtualIndex) => (
      <p className='logo-act' key={virtualIndex} dangerouslySetInnerHTML={{
        __html: `
          <div class='sr-head-wrap-notes'>
                      <h4> ${virtual.addedUserDetails ? `${virtual.addedUserDetails.firstName} ${virtual.addedUserDetails.lastName} added ` : '' }Virtual Meeting Note: ${virtual.date}</h4> 
        <p class='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div class='sr-head-wrap-notes-2'>${virtual.text}</div>
         
        ` }} />
    ))}
    {/* Display phone meetings */}
    {log.phoneMeeting?.map((phone, phoneIndex) => (
      <p className='logo-act' key={phoneIndex} dangerouslySetInnerHTML={{
        __html: `
          <div class='sr-head-wrap-notes'>
                      <h4> ${phone.addedUserDetails ? `${phone.addedUserDetails.firstName} ${phone.addedUserDetails.lastName} added ` : '' }Phone Meeting Note: ${phone.date}</h4> 
        <p class='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div class='sr-head-wrap-notes-2'>${phone.text}</div>
          
        ` }} />
    ))}
    {/* Display emails */}
    {log.email?.map((email, emailIndex) => (
      <p className='logo-act' key={emailIndex} dangerouslySetInnerHTML={{
        __html: `
          <div class='sr-head-wrap-notes'>
            <h4> ${email.addedUserDetails ? `${email.addedUserDetails.firstName} ${email.addedUserDetails.lastName} added ` : '' }Email Note: ${email.date}</h4> 
            <p class='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div class='sr-head-wrap-notes-2'>${email.text}</div>
         
        ` }} />
    ))}
  </div>
))}

                </div>
                <div className='notesBlock'>
                    <h4>Add New Notes</h4>
                    <div className='editor-file-upload'>
                    <div style={{ position: 'relative' }}>

                    <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
                ref={editorRef}
            />
            
            {showTooltip && mentionSearch && filteredUsers.length > 0 && (
                <div
                    ref={tooltipRef}
                    style={{
                        position: 'absolute',
                        top: '84px',  // Adjust to fit your layout
                        left: '10px', // Adjust to fit your layout
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 9,
                        width: '200px',
                        padding: '5px',
                        maxHeight: '150px',
                        overflowY: 'auto',  // Allow scrolling if many results
                    }}
                >
                    <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                        {filteredUsers.map(user => (
                            <li
                                key={user.id}
                                style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #eee',
                                }}
                                onClick={() => insertMention(user)}
                            >
                                {user.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            </div>
                        <div className='upload-files'>
                            <input type="file" multiple name='media' onChange={handleFileChange}                 
                            ref={fileInputRef} 
                            />
                            <div className='inner-div-uploads'>
                            </div>
                        </div>
             
                    </div>
                    <button onClick={handleSubmit} className="global-icon-btn orange-btn">
                        Add Note
                    </button>
                </div>
                <div className="actionBlock">
                    <div className="buttonTags">
                        <h4>Actions</h4>
                        <div className="actionTags">
                            
                            <button className="primaryBtn darkBtn" onClick={handleShowLogModal}>Log Opportunity Activity </button>
                            {userData?.user?.roleId === 1 ? (
                                <>
                            <button className="primaryBtn darkBtn" onClick={handleShowModalFollow}>Set Follow Up Date </button>
                            <span className="global-icon-btn" onClick={(e) => setShowTeam(true)}>
                                Invite Team Members To Opportunity
                            </span>
                            <button className="primaryBtn darkBtn" onClick={handleShowModal}>Convert Opportunity Into Project</button></>
                            ) : '' }
                        </div>
                    </div>
                </div>
              
                <div className="teamMembers">
                    <h4>Team Members</h4>
                    <div className="teamMembersTags">
                        {TeamMembers?.map((member) => (
                            <div key={member.id} className='teamdetails'>
                                <div className='teamMemeberImage'>
                                <svg
                                    onClick={() => handleRemove(member.email)}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ cursor: 'pointer' }}
                                >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                                    <img src={member.profilePictureUrl} alt="Team Member" />
                                </div>
                                <div className='teamMemberName'>
                                    <span>{member.firstName} {member.lastName}</span>
                                </div>
                            </div>
                        ))}


                        {Array.isArray(selectedTeamMembers) && selectedTeamMembers.map((member) => (
                            <div key={member?.id} className='teamdetails'>
                                <div className='teamMemeberImage'>
                                <svg
                                    onClick={() => handleRemoveTeamMembers(member.value)}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ cursor: 'pointer' }}
                                >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                                    <img src={member.profileImage} alt="Team Member" />
                                </div>
                                <div className='teamMemberName'>
                                    <span>{member?.label}</span>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
                {userData?.user?.roleId === 1 ? (
                <div className="form-btn-rows">
                    <button onClick={handleSubmit} className="global-icon-btn orange-btn">
                        Save
                    </button>
                    <button onClick={handleShowModalDelete} className="global-icon-btn orange-btn">
                        Delete
                    </button>
                    <Link to={`/opportunities`}>
                        <button className="global-icon-btn orange-btn">
                            Cancel
                        </button>
                    </Link>
                </div>
                ) : '' }


                <ToastContainer autoClose={2500} theme='light' position='top-right'/>

                <AddTeamMember
                    show={showTeam}
                    setShow={setShowTeam}
                    onSelectTeamMembers={handleSelectTeamMembers}
                    additionalData= {TeamMembers}
                    selectedTeamMembers= {selectedTeamMembers}
                />
                <ConvertOpportunityModal showModal={showModal} setShowModal={setShowModal} id={id} TeamMembers={TeamMembers} />
                <SetFollowUpDateModal showFollowModal={showFollowModal} setShowFollowModal={setShowFollowModal} projectid={id}  onClose={refreshState} />
                <ActivityLogModal showLogModal={showLogModal} setShowLogModal={setShowLogModal} projectid={id} onClose={refreshState} />
                <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal}  projectid={id} />
            </div>
        </div>
    )
}

export default Details;