// reducers/authReducer.js
const initialState = {
    isAuthenticated: false,
    user: null,
    error: null,
  };
  
  const updateProfileReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case 'UPDATE_USER_PROFILE':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,  // Update user data in Redux
                error: null,
            };
      default:
        return state;
    }
  };
  
  export default updateProfileReducer;
  