import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function EditProject({ showEdit, setShowEdit, id,  onClose }) {
  const [timer, setTimer] = useState(null);
  const [projectName, setProjectName] = useState('');
  const userData = useSelector(state => state.auth.user);

  const handleClose = () => {
    clearTimeout(timer); 
    onClose(); 
    setShowEdit(false);
  };

  const updateProject = async () => {
    try {
      const response = await API.post(`/admin/ProjectName/${id}`, {
        userId: userData.user.id,
        projectName, // Send the updated project name
      });
      
      if (response.status === 200) {
        console.log(response.data);
        toast.success(response.data.message);
        const timerId = setTimeout(() => {
            handleClose();
        }, 500);
        setTimer(timerId);
      } else {
        toast.error('Failed to update project name');
      }
    } catch (error) {
      console.error('Error updating project name:', error);
      toast.error('Error updating project name');
    }
  };

  const getProjectName = async () => {
    try {
      const response = await API.get(`/admin/ProjectName/${id}`);
      
      if (response.status === 200) {
        setProjectName(response.data.projectName.opportunityName);
      } else {
        toast.error('Failed to fetch project details');
      }
    } catch (error) {
      console.error('Error fetching project name:', error);
      toast.error('Error fetching project name');
    }
  };

  useEffect(() => {
    if (showEdit) {
      getProjectName();
    }
  }, [showEdit]);

  return (
    <>
      <Modal centered show={showEdit} onHide={handleClose} className='add-team-modal'>
        <Modal.Header closeButton>
          <h4 className="modal-title">Edit Project Name</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            <input
              type='text'
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className='sr-edit-project'
              placeholder="Enter new project name"
            />
          </div>
          <div className="form-btn-rows">
            <button type="button" onClick={updateProject} className="btn global-icon-btn orange-btn">Update</button>
            <button type="button" onClick={handleClose} className="btn global-icon-btn">Cancel</button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditProject;
