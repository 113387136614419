import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { FileUploader } from "react-drag-drop-files";
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddNewCompany from '../opportunity/AddNewCompanyModel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useParams, Link, useNavigate } from 'react-router-dom'
import FileUpload from '../FileUpload/FileUpload';
import AddTeamMember from '../opportunity/AddTeamMember';
import { Bars } from 'react-loader-spinner';
import AssignChecklistModal from './Modals/AssignChecklistModal';


function AddOpportunity(option) {
    const [formData, setFormData] = useState({ opportunityName: '', opportunityStatus: '', opportunityAmount: '', notes: '' });
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [users, setTeamMembers] = useState([]);
    const [editorData, setEditorData] = useState('');
    // const [files, setFile] = useState({});
    const [companyNames, setCompanyNames] = useState({});
    const [show, setShow] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const title = "Add New Project";
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]); // State for selected users
    console.log("selectedUsers", selectedUsers);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const { id } = useParams();

    const [errors, setErrors] = useState({
        opportunityName: '',
        opportunityStatus: '',
        opportunityAmount: '',
        content: '',
    });

    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    console.log('selectedTeamMembers', selectedTeamMembers);

    const handleSelectTeamMembers = (selectedMembers) => {
        setSelectedTeamMembers(selectedMembers);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    }
    const handleCheckboxChange = ((event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCheckboxes([...selectedCheckboxes, value]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== value));
        }
    })

    // const handleChangefile = (file) => {
    //     setFile(file);
    // };

    function changeHandler(e) {
        if (e.target.name === 'image') {
            setFormData({ ...formData, [e.target.name]: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }
    const handleEditorChange = (notes) => {
        setFormData({ ...formData, notes });
    }
    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        let newErrors = {};
        e.preventDefault();
        
        // Validate form data
        if (!formData.opportunityName.trim()) {
            newErrors.opportunityName = 'Project Name is required';
        }
        if (!formData.opportunityStatus.trim()) {
            newErrors.opportunityStatus = 'Please Select Project Status';
        }
        if (!formData.opportunityAmount.trim()) {
            newErrors.opportunityAmount = 'Please Select Project Amount';
        }
        if (selectedCheckboxes.length === 0) {
            newErrors.servicesInterested = "Please Select at least one option";
        }
        
        setErrors(newErrors);
        
        // If there are no errors, proceed to submit the form
        if (Object.keys(newErrors).length === 0) {
            const data = new FormData();
            const token = localStorage.getItem('token');
    
            // Append form data to FormData
            Object.keys(formData).forEach((field) => {
                data.append(field, formData[field]);
            });
    
            // Append selected checkboxes
            selectedCheckboxes.forEach((service) => {
                data.append('servicesInterested', service);
            });
    
            files.forEach(file => {
                data.append("media", file);  // Ensure you're appending each file
            });

          // Only send the IDs of the invited users
            const invitedUserIds = selectedTeamMembers.map(member => member.user.id); // Extract user.id
            data.append('userInvited', JSON.stringify(invitedUserIds));

                        
            setLoading(true);
    
            // Submit the form data to the API
            API.post(`/admin/newprojectss/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    toast.success("Project Created");
                    setFormData(false);
                    setTimeout(() => navigate(`/clients/client-detail/${id}`), 2000);
                } else {
                    setLoading(false);
                    toast.error('Project not saved', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error('An error occurred while saving the project', {
                    position: toast.POSITION.TOP_CENTER
                });
                console.error('Error submitting form:', error);
            });
        }
    };
    
    const getClientsList = async () => {
        try {
            setLoading(true);
            const response = await API.get('/admin/company-names');
            setCompanyNames(response.data);
            setLoading(false);

        } catch (e) {
            console.error(e);
            setLoading(false);

        }
    }

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await API.get('/admin/addTeam');
            setTeamMembers(response.data.data);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    const fetchAssignedUsers = async (clientId) => {
        try {
            const response = await API.get(`/admin/assigned-users/${id}`); // Adjust the endpoint as needed
            if (response.status === 200) {
                setSelectedTeamMembers(response.data);
            }
        } catch (error) {
            console.error('Error fetching assigned users:', error);
            toast.error('Error fetching assigned users', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    console.log("users", users);
    const options = users.map((user) => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName} `,
    }));

    useEffect(() => {
        dispatch(pageTitle(title));
        getClientsList();
        fetchAssignedUsers();
        fetchUsers();
    }, [])

    const handleClose = () => {
        setShowTeam(false); 
    };

    const [files, setFiles] = useState([]);
    console.log("files", files);
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles([...files, ...selectedFiles]);
    };

    return (
        <div className="main-dashboard-admin sr-cstm-padding">
            <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>
            
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
            {loading &&
                <div className='rotate-line-loader'>
                    <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
                </div>}
            <form className="opp-form" onSubmit={handleSubmit}>
                <div className="addNewOppFields">
                    <div className="fieldsBlock">
                        <div className="field opportunitiesName ">
                            <label htmlFor="">Project Name<span className='' style={{ color: 'red' }}>*</span></label>
                            <input type="text" placeholder="" name='opportunityName' onChange={handleChange} maxLength={32} />
                            {errors.opportunityName && <span style={{ color: 'red' }}>{errors.opportunityName}</span>}
                        </div>
                        <div className="field ">
                            <label htmlFor="">Project Status<span className='' style={{ color: 'red' }}>*</span></label>
                            <div className="selectDropdown">
                                <select name="opportunityStatus" id="types" onChange={handleChange}>
                                    <option value="">Please Select</option>
                                    <option value="Initial Discussions">Initial Discussions</option>
                <option value="Awaiting Proposal">Awaiting Proposal</option>
                <option value="Proposal Sent">Proposal Sent</option>
                <option value="Project Lost">Project Lost</option>
                <option value="Project Won">Project Won</option>
                                </select>
                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988"
                                        viewBox="0 0 13.971 7.988">
                                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward"
                                            d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                                            transform="translate(20.168 -11.246) rotate(90)" />
                                    </svg>
                                </div>
                            </div>
                            {errors.opportunityStatus && <span style={{ color: 'red' }}>{errors.opportunityStatus}</span>}
                        </div>
                        <div className="field ">
                            <label htmlFor="">Project Amount<span className='' style={{ color: 'red' }}>*</span></label>
                            <div className='dollarWrap'>
                                <div className='dollarSign'>$</div>
                                <input type="number" placeholder="" min='0' name='opportunityAmount' onChange={handleChange} onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                    if (e.target.value.length > 5) {
                                        e.target.value = e.target.value.slice(0, 5); // Limit input to 5 digits
                                    }
                                }} />
                            </div>
                            {errors.opportunityAmount && <span style={{ color: 'red' }}>{errors.opportunityAmount}</span>}
                        </div>

                        <div className="field checkBoxOptions">
                            <div className="ServicesInterested">
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' className='custom-checkbox' value='Development' onChange={handleCheckboxChange} />
                                    <span>Development</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='Design' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>Design</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='Automation' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>Automation</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='SEO' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>SEO</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='PPC' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>PPC</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='Social' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>Social</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='Email' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>Email</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='Reputation' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>Reputation</span>
                                </div>
                                <div className="field">
                                    <input type="checkbox" name='servicesInterested' value='Other' className='custom-checkbox' onChange={handleCheckboxChange} />
                                    <span>Other</span>
                                </div>
                            </div>
                            {errors.servicesInterested && <span style={{ color: 'red' }}>{errors.servicesInterested}</span>}
                        </div>

                    </div>
                </div>

                <div className='notesBlock'>
                    <h4>Add New Notes</h4>
                    <div className='editor-file-upload'>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                setFormData({ ...formData, notes: editor.getData() });
                            }}
                        />
                        {/* <FileUpload /> */}
                        <div className='upload-files'>
                            <input type="file" multiple name='media' onChange={handleFileChange} />
                            <div className='inner-div-uploads'>
                            </div>
                            </div>
                    </div>
                </div>
                <div className="actionBlock">
                    <div className="buttonTags">
                        <h4>Actions</h4>
                        <div className="actionTags">
                            <span className="global-icon-btn" onClick={(e) => setShowTeam(true)}>
                                Invite Team Members To Project
                            </span>
                        </div>
                    </div>
                </div>
                <div className="teamMembers">
                    <h4>Team Members</h4>
                    <div className="teamMembersTags">
                    {selectedTeamMembers.map((member) => (
                        <div key={member.id} className='teamdetails'>
                            <div className='teamMemeberImage'>
                            <img src={member?.profilePictureUrl || "https://t4.ftcdn.net/jpg/02/44/43/69/360_F_244436923_vkMe10KKKiw5bjhZeRDT05moxWcPpdmb.jpg"} alt="Team Member" />
                            </div>
                            <div className='teamMemberName'>
                                {/* Accessing first and last names from the user object */}
                                <span>{`${member?.user?.firstName} ${member?.user?.lastName}`}</span>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className='button-global-submit'>
                    <button className="global-icon-btn orange-btn" type="submit" id="save">Save</button>
                    <Link to={`/clients/client-detail/${id}`} className="global-icon-btn">Cancel</Link>
                </div>
            </form>
            <AddNewCompany
                show={show}
                setShow={setShow}
            />
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' />
            <AddTeamMember
                show={showTeam}
                setShow={setShowTeam}
                onSelectTeamMembers={handleSelectTeamMembers}
                selectedTeamMembers = {selectedUsers}
            />
        </div>
    )
}

export default AddOpportunity
