import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { Bars } from 'react-loader-spinner';

// Utility function to format date
function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    // Extracting time
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

    return `${formattedDate}, ${formattedTime}`;
}

function ActivityLogs() {
    const dispatch = useDispatch();
    const [activityLogs, setActivityLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const title = 'All Activity Log List';

    useEffect(() => {
        dispatch(pageTitle(title));
        fetchData(currentPage);
    }, [currentPage]);

    const fetchData = async (page) => {
        setLoading(true);
        try {
            const response = await API.get(`/admin/allActivityLog?page=${page}`);
            setActivityLogs(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching activity logs:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPagination = () => {
        const pages = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button key={i} onClick={() => handlePageChange(i)} className={currentPage === i ? 'active' : ''}>
                        {i}
                    </button>
                );
            }
        } else {
            if (currentPage <= 2) {
                for (let i = 1; i <= 3; i++) {
                    pages.push(
                        <button key={i} onClick={() => handlePageChange(i)} className={currentPage === i ? 'active' : ''}>
                            {i}
                        </button>
                    );
                }
                pages.push(<span key="ellipsis1">...</span>);
                pages.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)} className={currentPage === totalPages ? 'active' : ''}>
                        {totalPages}
                    </button>
                );
            } else if (currentPage >= totalPages - 1) {
                pages.push(
                    <button key={1} onClick={() => handlePageChange(1)} className={currentPage === 1 ? 'active' : ''}>
                        1
                    </button>
                );
                pages.push(<span key="ellipsis2">...</span>);
                for (let i = totalPages - 2; i <= totalPages; i++) {
                    pages.push(
                        <button key={i} onClick={() => handlePageChange(i)} className={currentPage === i ? 'active' : ''}>
                            {i}
                        </button>
                    );
                }
            } else {
                pages.push(
                    <button key={1} onClick={() => handlePageChange(1)} className={currentPage === 1 ? 'active' : ''}>
                        1
                    </button>
                );
                pages.push(<span key="ellipsis3">...</span>);
                pages.push(
                    <button key={currentPage - 1} onClick={() => handlePageChange(currentPage - 1)} className={currentPage === currentPage - 1 ? 'active' : ''}>
                        {currentPage - 1}
                    </button>
                );
                pages.push(
                    <button key={currentPage} onClick={() => handlePageChange(currentPage)} className="active">
                        {currentPage}
                    </button>
                );
                pages.push(
                    <button key={currentPage + 1} onClick={() => handlePageChange(currentPage + 1)} className={currentPage === currentPage + 1 ? 'active' : ''}>
                        {currentPage + 1}
                    </button>
                );
                pages.push(<span key="ellipsis4">...</span>);
                pages.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)} className={currentPage === totalPages ? 'active' : ''}>
                        {totalPages}
                    </button>
                );
            }
        }
        return pages;
    };

    return (
        <div className="main-dashboard-admin">
            {loading && (
                <div className='rotate-line-loader'>
                    <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
                </div>
            )}
            <div className="teamInfoTable email-table">
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="message">Log Description</th>
                            <th className="createdAt">Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activityLogs.map(log => (
                            <tr key={log.id}>
                                <td>{log.description}</td>
                                <td>{formatDate(log.createdAt)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                {currentPage > 1 && <button className='sr-cstm-nav-btn' onClick={handlePreviousPage}>Previous</button>}
                {renderPagination()}
                {currentPage < totalPages && <button className='sr-cstm-nav-btn' onClick={handleNextPage}>Next</button>}
            </div>
        </div>
    );
}

export default ActivityLogs;
