import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function Discussions() {
    const dispatch = useDispatch();
    const title = 'Discussions';
    const [discussions, setDiscussion] = useState([]);
    const { id } = useParams();
    const [value, setValue] = useState('');
    const [expandedDiscussion, setExpandedDiscussion] = useState(null); // Track expanded discussions
    const [dropdownOpen, setDropdownOpen] = useState(null); // Track which status is clicked
    const [newStatus, setNewStatus] = useState(''); // Track selected status in dropdown
    const navigate = useNavigate();
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;

    const getDiscussionsOfProject = () => {
        API.get(`/admin/get-discussions-project/${id}`)
            .then((response) => {
                const sortedDiscussions = response.data.data.sort((a, b) =>
                    new Date(b.createdAt) - new Date(a.createdAt)
                );
                console.log("sortedDiscussions", response);
                setDiscussion(sortedDiscussions);
            }).catch((error) => {
                console.log(error);
            });
    }

    const handleSearch = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    const filterBySearch = discussions.filter(discussion => {
        if (typeof value === 'undefined' || value.trim() === '') {
            return discussion;
        } else {
            const lowercasedValue = value.toLowerCase();
            return (
                discussion?.title.toLowerCase().includes(lowercasedValue) ||
                discussion?.description.toLowerCase().includes(lowercasedValue) ||
                discussion?.addedByUser?.firstName.toLowerCase().includes(lowercasedValue) ||
                new Date(discussion?.createdAt).toLocaleString().includes(value)
            );
        }
    });

    useEffect(() => {
        dispatch(pageTitle(title));
        getDiscussionsOfProject();
    }, []);

    // Handle the toggle for expanded discussion description
    const handleReadMoreToggle = (discussionId) => {
        setExpandedDiscussion(expandedDiscussion === discussionId ? null : discussionId);
    }

    // Helper to truncate description if it's too long
    const truncateDescription = (description) => {
        if (description.length > 50) {
            return `${description.slice(0, 50)}...`;
        }
        return description;
    }

    const handleStatusClick = (discussionId) => {
        setDropdownOpen(dropdownOpen === discussionId ? null : discussionId); // Toggle dropdown visibility
    };

    const handleStatusChange = async (discussionId, newStatus) => {
        const oldStatus = discussions.find(discussion => discussion.id === discussionId)?.status;
        const currentDate = new Date().toLocaleDateString();

        // Update the local state
        setDiscussion(discussions.map(discussion =>
            discussion.id === discussionId ? { ...discussion, status: newStatus } : discussion
        ));

        try {
            // Update the status on the server
            await API.put(`/admin/updateStatus`, { status: newStatus, id: discussionId });
            console.log('Status updated successfully');
            await getDiscussionsOfProject(); // Refresh the discussion list

        } catch (error) {
            console.error('Error updating status or posting comment:', error);
        }
    }

    return (
        <div className='main-dashboard-admin'>
            <div className='discussion-bar'>
                <div className="dis-head">
                    <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(`/clients/client-detail/project-detail/${id}`)}> <i className="fas fa-sign-out-alt"></i></button>
                    <h6>Discussions</h6>
                </div>
                <div className='search-bar'>
                    <div className='dis-btn'>
                        <Link to={`/clients/client-detail/project-detail/discussions/new-discussion/${id}`}>New Discussion</Link>
                    </div>
                </div>
            </div>
            <div className="teamInfoTable discussion-table">
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="data">ID</th>
                            <th className="name">User</th>
                            <th className="projectName">Discussion Title</th>
                            <th className="message">Description</th>
                            <th className="createdAt">Last Updated</th>
                            <th className="status">Status</th>
                            <th>Comments</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filterBySearch?.length > 0 ?
                                filterBySearch.map((discussion) => (
                                    <tr key={discussion?.id}>
                                        <td className="projectName">
                                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>D - {discussion?.serialNo}</Link>
                                        </td>
                                        <td className="name">
                                            <LazyImageWithSpinner
                                                src={discussion?.addedByUser?.profilePictureUrl}
                                                alt="Profile"
                                                style={{ maxWidth: '50px', marginRight: '10px' }}
                                            />
                                            <p>{discussion?.addedByUser?.firstName}</p>
                                        </td>
                                        <td className="message">
                                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>
                                                <p>{discussion?.title}</p>
                                            </Link>
                                        </td>
                                        
                                        <td className="createdAt">
                                            <p>
                                                {expandedDiscussion === discussion.id
                                                    ? new DOMParser().parseFromString(discussion.comments[discussion.comments.length - 1].comment, 'text/html').body.textContent
                                                    : truncateDescription(new DOMParser().parseFromString(discussion.comments[discussion.comments.length - 1].comment, 'text/html').body.textContent)}
                                            </p>
                                            {new DOMParser().parseFromString(discussion.comments[discussion.comments.length - 1].comment, 'text/html').body.textContent.length > 50 && (
                                                <button onClick={() => handleReadMoreToggle(discussion.id)}>
                                                    {expandedDiscussion === discussion.id ? "Read Less" : "Read More"}
                                                </button>
                                            )}
                                        </td>
                                        <td className="view">
                                            <p>
                                                {discussion.comments.length > 0 && discussion.comments[discussion.comments.length - 1].createdAt
                                                    ? new Date(discussion.comments[discussion.comments.length - 1].createdAt).toLocaleString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true, // Ensures AM/PM format
                                                    })
                                                    : new Date(discussion.createdAt).toLocaleString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true,
                                                    })}
                                            </p>
                                        </td>
                                        <td className="view" onClick={() => handleStatusClick(discussion?.id)}>
                                            {discussion?.status}
                                            {dropdownOpen === discussion?.id && (
                                                <div className="dropdown">
                                                    <ul>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'On Hold')}>On Hold</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Work In Progress')}>Work In Progress</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'QA Review')}>QA Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'PM Review')}>PM Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Management Review')}>Management Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Client Review')}>Client Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Pending Go-Live')}>Pending Go-Live</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Complete')}>Complete</li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        <td className="com">
                                            {discussion?.comments?.length === 0 ? 'No' : discussion?.comments?.length} Comments
                                        </td>
                                        <td className="com">
                                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>View</Link>
                                        </td>
                                    </tr>
                                ))
                                : 'No discussion found'
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Discussions;
