import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { FileUploader } from "react-drag-drop-files";
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddNewCompany from './AddNewCompanyModel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileUpload from '../FileUpload/FileUpload';
import AddTeamMember from './AddTeamMember';
import { Bars } from 'react-loader-spinner';
import Select from 'react-select';
import { useParams, Link ,useNavigate ,useLocation} from 'react-router-dom'


function AddOpportunity() {
    const [formData, setFormData] = useState({ companyName: '', companyId: '', opportunityName: '', opportunityStatus: '', opportunityAmount: '', notes:'' });
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [users, setTeamMembers] = useState([]);
    const [editorData, setEditorData] = useState('');
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [show, setShow] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const dispatch = useDispatch();
    const title = "Add New Opportunity";
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        companyName: '',
        opportunityName: '',
        oppname: '',
        opportunityStatus: '',
        opportunityAmount: '',
        content: '',
    });

    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

    const handleSelectTeamMembers = (selectedMembers) => {
    console.log('selectedTeamMembers', selectedTeamMembers);
      setSelectedTeamMembers(selectedMembers);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles([...files, ...selectedFiles]);
    };
    
      const handleDelete = (index) => {
        const updatedFiles = files.filter((file, i) => i !== index);
        setFiles(updatedFiles);
      };
    
      const getFileDisplay = (file) => {
        if (file.type.startsWith('image/')) {
          return <div><span><img src={URL.createObjectURL(file)} alt={file.name} /></span><p>{file.name}</p></div>;
        } else if (file.name.match(/\.(zip)$/i)) {
          return <div><span><img src='/assets/images/zip.png' alt='' /></span><p>{file.name}</p></div>;
        } else if (file.name.match(/\.(pdf)$/i)) {
            return <div> <span><img src='/assets/images/pdf.png' alt='' /></span> <p>{file.name}</p></div>;
        } else if (file.name.match(/\.(doc|docx)$/i)) {
          return <div> <span><img src='/assets/images/doc.png' alt='' /></span> <p>{file.name}</p></div>;
        } else {
          return <div> <span><img src='/assets/images/document.png'   alt='' /></span> <p>{file.name}</p></div>;
        }
      };
    
    const handleCompanyChange = (selectedOption) => {
        setFormData({
            ...formData,
            companyName: selectedOption.label, // Set the display name
            companyId: selectedOption.value, // Store the ID for submission
        });
    };
    

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCheckboxes([...selectedCheckboxes, value]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== value));
        }
    };

    const handleChangefile = (file) => {
        setFiles(file);
    };

    const handleEditorChange = (notes) => {
        setFormData({ ...formData, notes });
    };

    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!formData.companyName) {
            newErrors.companyName = "Opportunity Company Name is Required";
        }
        if (!formData.opportunityName.trim()) {
            newErrors.opportunityName = 'Opportunity Name is Required';
        }
        if (!formData.opportunityStatus.trim()) {
            newErrors.opportunityStatus = 'Please Select Opportunity Status';
        }
        if (!formData.opportunityAmount.trim()) {
            newErrors.opportunityAmount = 'Please Select Opportunity Amount';
        }
        if (selectedCheckboxes.length === 0) {
            newErrors.servicesInterested = "Please Select at least one option";
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const data = new FormData();
            const token = localStorage.getItem('token');
            Object.keys(formData).forEach((field) => {
                data.append(field, formData[field]);
            });
            selectedCheckboxes.forEach((service) => {
                data.append('servicesInterested', service);
            });
            Object.keys(files).forEach((key) => {
                if (key !== 'length') data.append("media", files[key]);
            });
            data.append('userInvited', JSON.stringify(selectedTeamMembers));

            setLoading(true);
            API.post('/admin/oppurtunity', data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    toast.success("Opportunity Created");
                    setFormData({ companyName: '', companyId: '', opportunityName: '', opportunityStatus: '', opportunityAmount: '', notes:'' });
                    setTimeout(() => navigate('/opportunities'), 2000);
                } else {
                    setLoading(false);
                    toast.error('Opportunity not saved', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error('An error occurred while saving the opportunity');
            });
        }
    };

    const getClientsList = async () => {
        try {
            setLoading(true);
            const response = await API.get('/admin/company-names');
            console.log("response", response);
            const companies = response.data.companyNames.map((company) => ({
                value: company.id,
                label: company.name
            }));
            console.log("companies", companies);

            setCompanyOptions(companies);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const refreshState = (newCompany) => {
        if (newCompany) {
            const selectedCompany = {
                value: newCompany.value, 
                label: newCompany.label 
            };
    
            // Add new company to the options list and select it
            setCompanyOptions((prevOptions) => [...prevOptions, selectedCompany]);
    
            // Update the form data to select the newly added company
            setFormData({
                ...formData,
                companyName: selectedCompany.label,
                companyId: selectedCompany.value
            });
        }
        getClientsList(); 
    };
    
    

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await API.get('/admin/addTeam');
            setTeamMembers(response.data.data);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        dispatch(pageTitle(title));
        getClientsList();
        fetchUsers();
    }, []);

    const handleClose = () => {
        setShowTeam(false);
    };

    return (
        <div className="main-dashboard-admin">
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
            <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>
        
             {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <form className='opp-form' onSubmit={handleSubmit} encType='multipart/form-data'>
                <div className="addNewOppFields">
                    <div className="fieldsBlock">
                    <div className="field">
                            <label htmlFor="">Opportunity Company Name<span style={{ color: 'red' }}>*</span></label>
                            <div className="selectDropdown">
                            <Select
                                options={companyOptions}
                                onChange={handleCompanyChange}
                                placeholder=""
                                value={companyOptions.find(option => option.value === formData.companyId)} // Set default selected value
                            />

                                {/* <Select
                                    options={companyOptions}
                                    onChange={handleCompanyChange}
                                    placeholder=""
                                /> */}
                            </div>
                            <span className='add-new-company' onClick={() => setShow(true)}><i className="fa fa-plus" aria-hidden="true"></i> Add New Company</span>
                            {errors.companyName && <span className='error-form-field' style={{ color: 'red' }}>{errors.companyName}</span>}
                        </div>

                        <div className="field opportunitiesName ">
                            <label htmlFor="">Opportunity Name<span className='' style={{ color: 'red' }}>*</span></label>
                            <input type="text" placeholder="" name='opportunityName' onChange={handleChange} maxLength={32} />
                            {errors.opportunityName && <span style={{ color: 'red' }}>{errors.opportunityName}</span>}
                        </div>
                        <div className="field ">
                            <label htmlFor="">Opportunity Status<span className='' style={{ color: 'red' }}>*</span></label>
                            <div className="selectDropdown">
                                <select name="opportunityStatus" id="types" onChange={handleChange}>
                                    <option value="">Please Select</option>
                                    <option value="Initial Discussions">Initial Discussions</option>
                                    <option value="Awaiting Proposal">Awaiting Proposal</option>
                                    <option value="Proposal Sent">Proposal Sent</option>
                                    <option value="Project Lost">Project Lost</option>
                                    <option value="Project Won">Project Won</option>
                                </select>
                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988"
                                        viewBox="0 0 13.971 7.988">
                                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward"
                                            d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                                            transform="translate(20.168 -11.246) rotate(90)" />
                                    </svg>
                                </div>
                            </div>
                            {errors.opportunityStatus && <span style={{ color: 'red' }}>{errors.opportunityStatus}</span>}
                        </div>
                        <div className="field ">
                            <label htmlFor="">Opportunity Amount<span className='' style={{ color: 'red' }}>*</span></label>
                            <div className='dollarWrap'>
                            <div className='dollarSign'>$</div>
                            <input type="number" placeholder="" min='0'  name='opportunityAmount' onChange={handleChange} onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                    
                                }} />
                            </div>
                            {errors.opportunityAmount && <span style={{ color: 'red' }}>{errors.opportunityAmount}</span>}
                        </div>

                        <div className="field checkBoxOptions">
    <div className="ServicesInterested">
        <div className="field">
            <input type="checkbox" name='servicesInterested' className='custom-checkbox' value='Development' onChange={handleCheckboxChange} />
            <span>Development</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Design' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Design</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Automation' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Automation</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='SEO' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>SEO</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='PPC' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>PPC</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Social' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Social</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Email' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Email</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Reputation' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Reputation</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Other' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Other</span>
        </div>
    </div>
    {errors.servicesInterested && <span style={{ color: 'red' }}>{errors.servicesInterested}</span>}
</div>

                    </div>
                </div>

                <div className='notesBlock'>
                    <h4>Add New Notes</h4>
                    <div className='editor-file-upload'>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                setFormData({ ...formData, notes: editor.getData() });
                            }}
                        />
                        {/* <FileUpload /> */}
                        <div className='upload-files'>
      <input type="file" multiple name='media' onChange={handleFileChange} />
      <div className='inner-div-uploads'>
        {/* {files?.map((file, index) => (
          <div key={index} className='single-file-uploaded'>
            {getFileDisplay(file)}
            <button onClick={() => handleDelete(index)}>
            <svg width="25" height="25" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>
            </button>
          </div>
        ))} */}
      </div>
    </div>
                    </div>
                </div>



                <div className="actionBlock">
                    <div className="buttonTags">
                        <h4>Actions</h4>
                        <div className="actionTags">
                            {/* <button className="primaryBtn darkBtn">Log Opportunity Activity </button> */}
                            {/* <button className="primaryBtn darkBtn">Set Follow Up Date </button> */}
                            <span className="global-icon-btn" onClick={(e)=> setShowTeam(true)}>
                                Invite Team Members To Opportunity
                            </span>                           
                             {/* <button className="primaryBtn darkBtn">Convert Opportunity Into Project</button> */}
                        </div>     
                    </div>
                </div>
                <div className="teamMembers">
                     <h4>Team Members</h4>
                    <div className="teamMembersTags">
                        {selectedTeamMembers.map((member) => (
                              <div key={member.id} className='teamdetails'>
                                 <div className='teamMemeberImage'>
                                     <img src={member.profileImage	} alt="Team Member"/>
                                 </div>
                                 <div className='teamMemberName'>
                                     <span>{member.label}</span>
                                 </div>
                             </div>
                        ))}
                    </div>
                </div>

                <div className='button-global-submit'>
                    <button className="global-icon-btn orange-btn" type="submit" id="save">Save</button>
                    <Link to="/opportunities" className='global-icon-btn'>Cancel</Link>
                </div>
            </form>
            <AddNewCompany show={show} setShow={setShow} onClose={refreshState}  />
            <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
                      <AddTeamMember
                show={showTeam}
                setShow={setShowTeam}
                selectedTeamMembers={selectedTeamMembers}
                onSelectTeamMembers={handleSelectTeamMembers}
            />
        </div>

    )
}

export default AddOpportunity
