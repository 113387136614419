import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function PrimaryContactModal({ showPrimary, setShowPrimary, id, company, onClose }) {
  const [timer, setTimer] = useState(null);
  const [Admins, setAdmin] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedAdminDetails, setSelectedAdminDetails] = useState(null); // To store selected admin details
  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);

  const handleClose = () => {
    clearTimeout(timer); // Clear the timer if modal is closed manually
    setShowPrimary(false);
    onClose();
  };

const handleAdminChange = async (e) => {
  const adminId = e.target.value;  // Keeping this as a string
  setSelectedAdmin(adminId);

  // Find and set selected admin's details
  const adminDetails = Admins.find(admin => admin.id === parseInt(adminId));
  setSelectedAdminDetails(adminDetails);

  try {
    await updatePrimaryContact(adminId);
    toast.success("Primary contact updated successfully!");
  } catch (error) {
    console.error('Error updating primary contact:', error);
    toast.error('Failed to update primary contact');
  }
};


  const updatePrimaryContact = async (adminId) => {
    try {
      const response = await API.post('/admin/update-primary-contact', {
        companyId: id,
        adminId: adminId,
      });

      if (response.status === 200) {
        // Successfully updated
      } else {
        toast.error("Failed to update primary contact");
      }
    } catch (error) {
      console.error("Error in updatePrimaryContact:", error);
      throw error;
    }
  };

  const adminUsers = async () => {
    try {
      const response = await API.get(`/admin/admin-users`);
      if (response.status === 200) {
        setAdmin(response.data);
        console.log(response.data);
  
        // After fetching admin users, check if the selectedAdmin needs to be set
        if (response.data.length > 0) {
          const currentAdminId = await fetchCurrentPrimaryContact(); // get current primary contact
          console.log("currentAdminId", currentAdminId.id);
          if (currentAdminId) {
            setSelectedAdmin(String(currentAdminId.primaryContact)); // Convert to string
            
            // Find and set selected admin's details
            const adminDetails = response.data.find(
              admin => String(admin.id) === String(currentAdminId.primaryContact)
            );
            setSelectedAdminDetails(adminDetails);
          }
        }
      } else {
        toast.error('Failed to fetch admin users');
      }
    } catch (error) {
      console.error('Error fetching admin users:', error);
      toast.error('Failed to fetch admin users');
    }
  };
  

  const fetchCurrentPrimaryContact = async () => {
    try {
      const response = await API.get(`/admin/current-primary-contact/${id}`);
      if (response.status === 200) {
        console.log("response.data", )
        const currentAdminId = response.data.primaryAdminId;
        return currentAdminId; // Return the current admin ID for use in adminUsers
      } else {
        toast.error("Failed to fetch current primary contact");
        return null; // Return null if there's an error
      }
    } catch (error) {
      console.error("Error fetching current primary contact:", error);
      toast.error("Failed to fetch current primary contact");
      return null; // Return null if there's an error
    }
  };

  useEffect(() => {
    if (showPrimary) {
      adminUsers(); // Fetch all admin users
    }
  }, [showPrimary]);

  return (
    <>
      <Modal centered show={showPrimary} onHide={handleClose} className='add-team-modal check-primary'>
        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19.2"
              viewBox="0 0 24 19.2"
            >
              <path
                id="Icon_awesome-user-plus"
                data-name="Icon awesome-user-plus"
                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
              />
            </svg>
            Assign Primary Contact
          </h4>
        </Modal.Header>
        <Modal.Body>
        <div className='check-pr-box'>
          {Admins.map((admin) => (
            <div key={admin.id} className="admin_checkbox">
              <input
                type="radio"
                id={`admin-${admin.id}`}
                name="primaryContact"
                value={admin.id}
                className="form-check-input"
                checked={selectedAdmin === String(admin.id)}
                onChange={handleAdminChange}
              />
              <label htmlFor={`admin-${admin.id}`} className="form-check-label">
                {admin.firstName} {admin.lastName}
              </label>
            </div>
          ))}
          </div>

          {selectedAdminDetails && (
            <div className="admin-details">
              <p><strong>Name:</strong> {selectedAdminDetails.firstName} {selectedAdminDetails.lastName}</p>
              <p><strong>Email:</strong> {selectedAdminDetails.email}</p>
            </div>
          )}

          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PrimaryContactModal;
