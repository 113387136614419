import React, { useState } from 'react';
import KanbanCard from './task';
import CreateTask from './Model/createTask';
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
const KanbanColumn = ({ name, stage, tasks, onDragEnter, onDragEnd, columnId, boardId, onTaskCreated }) => {
  const [mouseIsHovering, setMouseIsHovering] = useState(false);
  const [showBoard, setShowBoard] = useState(false);

  const handleShowModalCreate = () => {
    setShowBoard(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setMouseIsHovering(false);

    const taskId = e.dataTransfer.getData('taskId');
    if (taskId) {
      onDragEnd(e, { id: taskId, columnId });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className='sr-main-column'
      onDragEnter={(e) => {
        setMouseIsHovering(true);
        onDragEnter(e, stage);
      }}
      onDragExit={() => setMouseIsHovering(false)}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <h4>{name} ({tasks.length})</h4>

      {tasks.map((task) => (
        <KanbanCard
          key={task.id}
          task={task}
          onDragEnd={onDragEnd}
          boardId ={boardId}
          onTaskCreated = {onTaskCreated}
        />
      ))}
      <div className="create-btn" onClick={handleShowModalCreate}>
        <div className='plus'>
          <img src="/assets/images/add.png" alt="add" />
        </div>
        <button onClick={handleShowModalCreate} className="btn btn-primary">Create Task</button>
      </div>
      <CreateTask
        showBoard={showBoard}
        setShowBoard={setShowBoard}
        columnId={columnId}
        boardId={boardId}
        onTaskCreated={onTaskCreated}
      />
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light' 
      />
    </div>
  );
};

export default KanbanColumn;