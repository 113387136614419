import React, { useState, useEffect } from 'react';

// Utility function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const isPM = hours >= 12;
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const amPm = isPM ? 'PM' : 'AM';

  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
  return `${formattedDate} ${formattedTime}`;
};

const TimeAgo = ({ updatedAt }) => {
  const [timeAgo, setTimeAgo] = useState('');
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    const calculateTimeAgo = () => {
      const updatedAtDate = new Date(updatedAt);
      setFormattedDate(formatDate(updatedAt)); // Set formatted date

      const now = new Date();
      const timeDifference = now - updatedAtDate;
      const secondsDifference = Math.floor(timeDifference / 1000);
      const minutesDifference = Math.floor(secondsDifference / 60);
      const hoursDifference = Math.floor(minutesDifference / 60);
      const daysDifference = Math.floor(hoursDifference / 24);

      if (daysDifference > 0) {
        setTimeAgo(`${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`);
      } else if (hoursDifference > 0) {
        setTimeAgo(`${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`);
      } else if (minutesDifference > 0) {
        setTimeAgo(`${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`);
      } else {
        setTimeAgo('Less than a minute ago');
      }
    };

    calculateTimeAgo();

    // Refresh time every minute
    const interval = setInterval(calculateTimeAgo, 60000);

    return () => clearInterval(interval);
  }, [updatedAt]);

  return (
      <p> {formattedDate} {timeAgo} </p>
  );
};

export default TimeAgo;
