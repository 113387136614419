import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'

function LeftMenu({ currentuser }) {
    const role = currentuser.role
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Manage sidebar state

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
    };

    return (
        <>
        <div className={`left-sidebar-admin ${isSidebarOpen ? 'open' : 'closed'}`}>
            
            <ul>
                {role === "admin" && (
                    <>
                      {/* <li>
                     <a onClick={toggleSidebar} className="toggle-sidebar-btn">
                        <span>
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span>
                        Menu
                    </a>
                      
                  
                    </li> */}
                        {/* <li><NavLink to="/dashboard">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20.624" height="16.041" viewBox="0 0 20.624 16.041">
                                <path id="Icon_awesome-tachometer-alt" data-name="Icon awesome-tachometer-alt" d="M10.312,2.25A10.312,10.312,0,0,0,1.4,17.747a1.133,1.133,0,0,0,.983.544H18.243a1.133,1.133,0,0,0,.983-.544,10.312,10.312,0,0,0-8.914-15.5Zm0,2.292a1.131,1.131,0,0,1,1.086.847,1.93,1.93,0,0,0-.124.239l-.33.991a1.116,1.116,0,0,1-.632.215,1.146,1.146,0,0,1,0-2.292ZM3.437,14.854a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,3.437,14.854ZM5.156,9.125A1.146,1.146,0,1,1,6.3,7.979,1.146,1.146,0,0,1,5.156,9.125Zm8.836-2.593L11.8,13.12A2.249,2.249,0,0,1,12.286,16H8.339a2.268,2.268,0,0,1,1.827-3.423l2.2-6.589a.859.859,0,1,1,1.63.544Zm.525,2.048.556-1.667a1.129,1.129,0,0,1,.4-.08,1.146,1.146,0,1,1,0,2.292,1.128,1.128,0,0,1-.951-.545Zm2.67,6.274a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,17.187,14.854Z" transform="translate(0 -2.25)" fill="#fff" />
                            </svg></span>
                            Dashboard</NavLink></li> */}
                        <li><NavLink to="/clients"><span><svg xmlns="http://www.w3.org/2000/svg" width="21.127" height="24.145" viewBox="0 0 21.127 24.145">
                            <path id="Icon_awesome-user-tie" data-name="Icon awesome-user-tie" d="M10.564,12.073A6.036,6.036,0,1,0,4.527,6.036,6.036,6.036,0,0,0,10.564,12.073Zm4.518,1.537-2.254,9.026-1.509-6.414,1.509-2.641H8.3l1.509,2.641L8.3,22.636,6.046,13.61A6.327,6.327,0,0,0,0,19.92v1.962a2.264,2.264,0,0,0,2.264,2.264h16.6a2.264,2.264,0,0,0,2.264-2.264V19.92A6.327,6.327,0,0,0,15.081,13.61Z" fill="#fff" />
                        </svg></span>
                            CLIENTS</NavLink></li>
                        <li><NavLink to="/progress"><span><svg xmlns="http://www.w3.org/2000/svg" width="14.337" height="16.385" viewBox="0 0 14.337 16.385">
                            <path id="Icon_awesome-percent" data-name="Icon awesome-percent" d="M3.584,7.168A3.584,3.584,0,1,0,0,3.584,3.582,3.582,0,0,0,3.584,7.168Zm0-5.12A1.536,1.536,0,1,1,2.048,3.584,1.536,1.536,0,0,1,3.584,2.048Zm7.168,7.168A3.584,3.584,0,1,0,14.337,12.8,3.582,3.582,0,0,0,10.752,9.216Zm0,5.12A1.536,1.536,0,1,1,12.289,12.8,1.536,1.536,0,0,1,10.752,14.337Zm1.8-14.33,1.011,0a.768.768,0,0,1,.63,1.21L2.477,16.052a.766.766,0,0,1-.627.326l-1.069,0a.768.768,0,0,1-.63-1.21L11.927.333A.766.766,0,0,1,12.554.006Z" fill="#fff" />
                        </svg></span>
                            PROGRESS</NavLink></li>
                        <li><NavLink to="/timeTracking"><span><svg xmlns="http://www.w3.org/2000/svg" width="23.561" height="23.561" viewBox="0 0 23.561 23.561">
                            <path id="Icon_awesome-clock" data-name="Icon awesome-clock" d="M12.343.563A11.781,11.781,0,1,0,24.124,12.343,11.779,11.779,0,0,0,12.343.563Zm2.712,16.631-4.19-3.045a.574.574,0,0,1-.233-.461V5.693a.572.572,0,0,1,.57-.57h2.28a.572.572,0,0,1,.57.57v6.541l3.016,2.195a.57.57,0,0,1,.124.8l-1.34,1.843A.574.574,0,0,1,15.056,17.193Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                        </svg></span>
                            TIME TRACKING</NavLink></li>
                        <li><NavLink to="/opportunities"><span><svg xmlns="http://www.w3.org/2000/svg" width="17.497" height="25.451" viewBox="0 0 17.497 25.451">
                            <path id="Icon_awesome-lightbulb" data-name="Icon awesome-lightbulb" d="M4.775,22.584a1.591,1.591,0,0,0,.266.879l.849,1.277a1.59,1.59,0,0,0,1.324.71h3.067a1.59,1.59,0,0,0,1.324-.71l.85-1.277a1.59,1.59,0,0,0,.266-.879l0-1.906H4.772ZM0,8.748A8.7,8.7,0,0,0,2.165,14.5a14.285,14.285,0,0,1,2.6,4.546c0,.013,0,.026.005.039h7.965c0-.013,0-.025.005-.039a14.285,14.285,0,0,1,2.6-4.546A8.747,8.747,0,1,0,0,8.748ZM8.748,4.772A3.981,3.981,0,0,0,4.772,8.748a.8.8,0,0,1-1.591,0A5.573,5.573,0,0,1,8.748,3.181a.8.8,0,0,1,0,1.591Z" transform="translate(0 0)" fill="#fff" />
                        </svg></span>
                            OPPORTUNITIES</NavLink></li>
                        <li><NavLink to="/team"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="18.724" viewBox="0 0 26.748 18.724">
                            <path id="Icon_awesome-users" data-name="Icon awesome-users" d="M4.012,10.274A2.675,2.675,0,1,0,1.337,7.6,2.677,2.677,0,0,0,4.012,10.274Zm18.724,0A2.675,2.675,0,1,0,20.061,7.6,2.677,2.677,0,0,0,22.736,10.274Zm1.337,1.337H21.4a2.667,2.667,0,0,0-1.885.777,6.113,6.113,0,0,1,3.139,4.572h2.758a1.336,1.336,0,0,0,1.337-1.337V14.287A2.677,2.677,0,0,0,24.073,11.612Zm-10.7,0A4.681,4.681,0,1,0,8.693,6.931,4.679,4.679,0,0,0,13.374,11.612Zm3.21,1.337h-.347a6.463,6.463,0,0,1-5.726,0h-.347A4.816,4.816,0,0,0,5.35,17.764v1.2a2.007,2.007,0,0,0,2.006,2.006H19.392A2.007,2.007,0,0,0,21.4,18.967v-1.2A4.816,4.816,0,0,0,16.584,12.949Zm-9.349-.56a2.667,2.667,0,0,0-1.885-.777H2.675A2.677,2.677,0,0,0,0,14.287v1.337a1.336,1.336,0,0,0,1.337,1.337H4.092A6.129,6.129,0,0,1,7.234,12.389Z" transform="translate(0 -2.25)" fill="#fff" />
                        </svg></span>
                            TEAM</NavLink></li>
                        <li><NavLink to="/email-logs"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.986" height="20.24" viewBox="0 0 26.986 20.24">
                            <path id="Icon_awesome-envelope" data-name="Icon awesome-envelope" d="M26.475,11.183a.317.317,0,0,1,.511.248V22.21a2.531,2.531,0,0,1-2.53,2.53H2.53A2.531,2.531,0,0,1,0,22.21V11.436a.315.315,0,0,1,.511-.248c1.181.917,2.746,2.082,8.122,5.988,1.112.812,2.989,2.519,4.86,2.509,1.882.016,3.795-1.729,4.865-2.509C23.734,13.271,25.294,12.1,26.475,11.183Zm-12.982,6.81c1.223.021,2.983-1.539,3.869-2.182,6.994-5.076,7.527-5.518,9.139-6.783a1.261,1.261,0,0,0,.485-1v-1a2.531,2.531,0,0,0-2.53-2.53H2.53A2.531,2.531,0,0,0,0,7.03v1a1.269,1.269,0,0,0,.485,1c1.613,1.26,2.145,1.708,9.139,6.783C10.51,16.454,12.27,18.014,13.493,17.993Z" transform="translate(0 -4.5)" fill="#fff" />
                        </svg></span>
                            EMAIL LOG</NavLink></li>
                        <li><NavLink to="/checklists"><span><img src='/assets/images/checklist.svg' /></span>
                            CHECKLISTS</NavLink></li>
                        <li><NavLink to="/sop/1"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="21.733" viewBox="0 0 26.748 21.733">
                        <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M4.179,20.092H.836A.836.836,0,0,0,0,20.928v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V20.928A.836.836,0,0,0,4.179,20.092Zm0-16.717H.836A.836.836,0,0,0,0,4.211V7.554a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V4.211A.836.836,0,0,0,4.179,3.375Zm0,8.359H.836A.836.836,0,0,0,0,12.57v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V12.57A.836.836,0,0,0,4.179,11.734Zm21.733,9.195H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V21.764A.836.836,0,0,0,25.912,20.928Zm0-16.717H9.195a.836.836,0,0,0-.836.836V6.718a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V5.047A.836.836,0,0,0,25.912,4.211Zm0,8.359H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V13.405A.836.836,0,0,0,25.912,12.57Z" transform="translate(0 -3.375)" fill="#fff" />
                        </svg></span>
                            SOPs</NavLink></li>
                            <li><NavLink to="/templates/1"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="21.733" viewBox="0 0 26.748 21.733">
                        <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M4.179,20.092H.836A.836.836,0,0,0,0,20.928v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V20.928A.836.836,0,0,0,4.179,20.092Zm0-16.717H.836A.836.836,0,0,0,0,4.211V7.554a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V4.211A.836.836,0,0,0,4.179,3.375Zm0,8.359H.836A.836.836,0,0,0,0,12.57v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V12.57A.836.836,0,0,0,4.179,11.734Zm21.733,9.195H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V21.764A.836.836,0,0,0,25.912,20.928Zm0-16.717H9.195a.836.836,0,0,0-.836.836V6.718a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V5.047A.836.836,0,0,0,25.912,4.211Zm0,8.359H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V13.405A.836.836,0,0,0,25.912,12.57Z" transform="translate(0 -3.375)" fill="#fff" />
                        </svg></span>
                            Templates</NavLink></li>
                            <li><NavLink to="/projectStatus"><span><img src='/assets/images/ProjectStatus.svg' /></span>
                            Project Status</NavLink></li>
                            </>
                )}

                {role === "team" && (
                    <>
                     
                        {/* <li><NavLink to="/dashboard">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20.624" height="16.041" viewBox="0 0 20.624 16.041">
                                <path id="Icon_awesome-tachometer-alt" data-name="Icon awesome-tachometer-alt" d="M10.312,2.25A10.312,10.312,0,0,0,1.4,17.747a1.133,1.133,0,0,0,.983.544H18.243a1.133,1.133,0,0,0,.983-.544,10.312,10.312,0,0,0-8.914-15.5Zm0,2.292a1.131,1.131,0,0,1,1.086.847,1.93,1.93,0,0,0-.124.239l-.33.991a1.116,1.116,0,0,1-.632.215,1.146,1.146,0,0,1,0-2.292ZM3.437,14.854a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,3.437,14.854ZM5.156,9.125A1.146,1.146,0,1,1,6.3,7.979,1.146,1.146,0,0,1,5.156,9.125Zm8.836-2.593L11.8,13.12A2.249,2.249,0,0,1,12.286,16H8.339a2.268,2.268,0,0,1,1.827-3.423l2.2-6.589a.859.859,0,1,1,1.63.544Zm.525,2.048.556-1.667a1.129,1.129,0,0,1,.4-.08,1.146,1.146,0,1,1,0,2.292,1.128,1.128,0,0,1-.951-.545Zm2.67,6.274a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,17.187,14.854Z" transform="translate(0 -2.25)" fill="#fff" />
                            </svg></span>
                            Dashboard</NavLink></li> */}
                        <li><NavLink to="/clients"><span><svg xmlns="http://www.w3.org/2000/svg" width="21.127" height="24.145" viewBox="0 0 21.127 24.145">
                            <path id="Icon_awesome-user-tie" data-name="Icon awesome-user-tie" d="M10.564,12.073A6.036,6.036,0,1,0,4.527,6.036,6.036,6.036,0,0,0,10.564,12.073Zm4.518,1.537-2.254,9.026-1.509-6.414,1.509-2.641H8.3l1.509,2.641L8.3,22.636,6.046,13.61A6.327,6.327,0,0,0,0,19.92v1.962a2.264,2.264,0,0,0,2.264,2.264h16.6a2.264,2.264,0,0,0,2.264-2.264V19.92A6.327,6.327,0,0,0,15.081,13.61Z" fill="#fff" />
                        </svg></span>
                            CLIENTS</NavLink></li>
                        <li><NavLink to="/progress"><span><svg xmlns="http://www.w3.org/2000/svg" width="14.337" height="16.385" viewBox="0 0 14.337 16.385">
                            <path id="Icon_awesome-percent" data-name="Icon awesome-percent" d="M3.584,7.168A3.584,3.584,0,1,0,0,3.584,3.582,3.582,0,0,0,3.584,7.168Zm0-5.12A1.536,1.536,0,1,1,2.048,3.584,1.536,1.536,0,0,1,3.584,2.048Zm7.168,7.168A3.584,3.584,0,1,0,14.337,12.8,3.582,3.582,0,0,0,10.752,9.216Zm0,5.12A1.536,1.536,0,1,1,12.289,12.8,1.536,1.536,0,0,1,10.752,14.337Zm1.8-14.33,1.011,0a.768.768,0,0,1,.63,1.21L2.477,16.052a.766.766,0,0,1-.627.326l-1.069,0a.768.768,0,0,1-.63-1.21L11.927.333A.766.766,0,0,1,12.554.006Z" fill="#fff" />
                        </svg></span>
                            PROGRESS</NavLink></li>
                        <li><NavLink to="/timeTracking"><span><svg xmlns="http://www.w3.org/2000/svg" width="23.561" height="23.561" viewBox="0 0 23.561 23.561">
                            <path id="Icon_awesome-clock" data-name="Icon awesome-clock" d="M12.343.563A11.781,11.781,0,1,0,24.124,12.343,11.779,11.779,0,0,0,12.343.563Zm2.712,16.631-4.19-3.045a.574.574,0,0,1-.233-.461V5.693a.572.572,0,0,1,.57-.57h2.28a.572.572,0,0,1,.57.57v6.541l3.016,2.195a.57.57,0,0,1,.124.8l-1.34,1.843A.574.574,0,0,1,15.056,17.193Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                        </svg></span>
                            TIME TRACKING</NavLink></li>
                        <li><NavLink to="/opportunities"><span><svg xmlns="http://www.w3.org/2000/svg" width="17.497" height="25.451" viewBox="0 0 17.497 25.451">
                            <path id="Icon_awesome-lightbulb" data-name="Icon awesome-lightbulb" d="M4.775,22.584a1.591,1.591,0,0,0,.266.879l.849,1.277a1.59,1.59,0,0,0,1.324.71h3.067a1.59,1.59,0,0,0,1.324-.71l.85-1.277a1.59,1.59,0,0,0,.266-.879l0-1.906H4.772ZM0,8.748A8.7,8.7,0,0,0,2.165,14.5a14.285,14.285,0,0,1,2.6,4.546c0,.013,0,.026.005.039h7.965c0-.013,0-.025.005-.039a14.285,14.285,0,0,1,2.6-4.546A8.747,8.747,0,1,0,0,8.748ZM8.748,4.772A3.981,3.981,0,0,0,4.772,8.748a.8.8,0,0,1-1.591,0A5.573,5.573,0,0,1,8.748,3.181a.8.8,0,0,1,0,1.591Z" transform="translate(0 0)" fill="#fff" />
                        </svg></span>
                            OPPORTUNITIES</NavLink></li>
                        <li><NavLink to="/sop/1"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="21.733" viewBox="0 0 26.748 21.733">
                        <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M4.179,20.092H.836A.836.836,0,0,0,0,20.928v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V20.928A.836.836,0,0,0,4.179,20.092Zm0-16.717H.836A.836.836,0,0,0,0,4.211V7.554a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V4.211A.836.836,0,0,0,4.179,3.375Zm0,8.359H.836A.836.836,0,0,0,0,12.57v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V12.57A.836.836,0,0,0,4.179,11.734Zm21.733,9.195H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V21.764A.836.836,0,0,0,25.912,20.928Zm0-16.717H9.195a.836.836,0,0,0-.836.836V6.718a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V5.047A.836.836,0,0,0,25.912,4.211Zm0,8.359H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V13.405A.836.836,0,0,0,25.912,12.57Z" transform="translate(0 -3.375)" fill="#fff" />
                        </svg></span>
                            SOPs</NavLink></li>
                            <li>
                            <li><NavLink to="/templates/1"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="21.733" viewBox="0 0 26.748 21.733">
                        <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M4.179,20.092H.836A.836.836,0,0,0,0,20.928v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V20.928A.836.836,0,0,0,4.179,20.092Zm0-16.717H.836A.836.836,0,0,0,0,4.211V7.554a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V4.211A.836.836,0,0,0,4.179,3.375Zm0,8.359H.836A.836.836,0,0,0,0,12.57v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V12.57A.836.836,0,0,0,4.179,11.734Zm21.733,9.195H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V21.764A.836.836,0,0,0,25.912,20.928Zm0-16.717H9.195a.836.836,0,0,0-.836.836V6.718a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V5.047A.836.836,0,0,0,25.912,4.211Zm0,8.359H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V13.405A.836.836,0,0,0,25.912,12.57Z" transform="translate(0 -3.375)" fill="#fff" />
                        </svg></span>
                            Templates</NavLink></li>
                        <a onClick={toggleSidebar} className="toggle-sidebar-btn">
                           <span>
                               <i class="fa fa-times" aria-hidden="true"></i>
                           </span>
                           
                       </a>
                       </li>
                        </>
                      
                )}
            </ul>
        </div>

         <div className={`left-sidebar-admin cstm-sidebar ${isSidebarOpen ? 'closed' : 'open'}`}>
            
         <ul>
             {role === "admin" && (
                 <>
                
                     {/* <li><NavLink to="/dashboard">
                         <span><svg xmlns="http://www.w3.org/2000/svg" width="20.624" height="16.041" viewBox="0 0 20.624 16.041">
                             <path id="Icon_awesome-tachometer-alt" data-name="Icon awesome-tachometer-alt" d="M10.312,2.25A10.312,10.312,0,0,0,1.4,17.747a1.133,1.133,0,0,0,.983.544H18.243a1.133,1.133,0,0,0,.983-.544,10.312,10.312,0,0,0-8.914-15.5Zm0,2.292a1.131,1.131,0,0,1,1.086.847,1.93,1.93,0,0,0-.124.239l-.33.991a1.116,1.116,0,0,1-.632.215,1.146,1.146,0,0,1,0-2.292ZM3.437,14.854a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,3.437,14.854ZM5.156,9.125A1.146,1.146,0,1,1,6.3,7.979,1.146,1.146,0,0,1,5.156,9.125Zm8.836-2.593L11.8,13.12A2.249,2.249,0,0,1,12.286,16H8.339a2.268,2.268,0,0,1,1.827-3.423l2.2-6.589a.859.859,0,1,1,1.63.544Zm.525,2.048.556-1.667a1.129,1.129,0,0,1,.4-.08,1.146,1.146,0,1,1,0,2.292,1.128,1.128,0,0,1-.951-.545Zm2.67,6.274a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,17.187,14.854Z" transform="translate(0 -2.25)" fill="#fff" />
                         </svg></span>
                         </NavLink></li> */}
                     <li><NavLink to="/clients"><span><svg xmlns="http://www.w3.org/2000/svg" width="21.127" height="24.145" viewBox="0 0 21.127 24.145">
                         <path id="Icon_awesome-user-tie" data-name="Icon awesome-user-tie" d="M10.564,12.073A6.036,6.036,0,1,0,4.527,6.036,6.036,6.036,0,0,0,10.564,12.073Zm4.518,1.537-2.254,9.026-1.509-6.414,1.509-2.641H8.3l1.509,2.641L8.3,22.636,6.046,13.61A6.327,6.327,0,0,0,0,19.92v1.962a2.264,2.264,0,0,0,2.264,2.264h16.6a2.264,2.264,0,0,0,2.264-2.264V19.92A6.327,6.327,0,0,0,15.081,13.61Z" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/progress"><span><svg xmlns="http://www.w3.org/2000/svg" width="14.337" height="16.385" viewBox="0 0 14.337 16.385">
                         <path id="Icon_awesome-percent" data-name="Icon awesome-percent" d="M3.584,7.168A3.584,3.584,0,1,0,0,3.584,3.582,3.582,0,0,0,3.584,7.168Zm0-5.12A1.536,1.536,0,1,1,2.048,3.584,1.536,1.536,0,0,1,3.584,2.048Zm7.168,7.168A3.584,3.584,0,1,0,14.337,12.8,3.582,3.582,0,0,0,10.752,9.216Zm0,5.12A1.536,1.536,0,1,1,12.289,12.8,1.536,1.536,0,0,1,10.752,14.337Zm1.8-14.33,1.011,0a.768.768,0,0,1,.63,1.21L2.477,16.052a.766.766,0,0,1-.627.326l-1.069,0a.768.768,0,0,1-.63-1.21L11.927.333A.766.766,0,0,1,12.554.006Z" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/timeTracking"><span><svg xmlns="http://www.w3.org/2000/svg" width="23.561" height="23.561" viewBox="0 0 23.561 23.561">
                         <path id="Icon_awesome-clock" data-name="Icon awesome-clock" d="M12.343.563A11.781,11.781,0,1,0,24.124,12.343,11.779,11.779,0,0,0,12.343.563Zm2.712,16.631-4.19-3.045a.574.574,0,0,1-.233-.461V5.693a.572.572,0,0,1,.57-.57h2.28a.572.572,0,0,1,.57.57v6.541l3.016,2.195a.57.57,0,0,1,.124.8l-1.34,1.843A.574.574,0,0,1,15.056,17.193Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/opportunities"><span><svg xmlns="http://www.w3.org/2000/svg" width="17.497" height="25.451" viewBox="0 0 17.497 25.451">
                         <path id="Icon_awesome-lightbulb" data-name="Icon awesome-lightbulb" d="M4.775,22.584a1.591,1.591,0,0,0,.266.879l.849,1.277a1.59,1.59,0,0,0,1.324.71h3.067a1.59,1.59,0,0,0,1.324-.71l.85-1.277a1.59,1.59,0,0,0,.266-.879l0-1.906H4.772ZM0,8.748A8.7,8.7,0,0,0,2.165,14.5a14.285,14.285,0,0,1,2.6,4.546c0,.013,0,.026.005.039h7.965c0-.013,0-.025.005-.039a14.285,14.285,0,0,1,2.6-4.546A8.747,8.747,0,1,0,0,8.748ZM8.748,4.772A3.981,3.981,0,0,0,4.772,8.748a.8.8,0,0,1-1.591,0A5.573,5.573,0,0,1,8.748,3.181a.8.8,0,0,1,0,1.591Z" transform="translate(0 0)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/team"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="18.724" viewBox="0 0 26.748 18.724">
                         <path id="Icon_awesome-users" data-name="Icon awesome-users" d="M4.012,10.274A2.675,2.675,0,1,0,1.337,7.6,2.677,2.677,0,0,0,4.012,10.274Zm18.724,0A2.675,2.675,0,1,0,20.061,7.6,2.677,2.677,0,0,0,22.736,10.274Zm1.337,1.337H21.4a2.667,2.667,0,0,0-1.885.777,6.113,6.113,0,0,1,3.139,4.572h2.758a1.336,1.336,0,0,0,1.337-1.337V14.287A2.677,2.677,0,0,0,24.073,11.612Zm-10.7,0A4.681,4.681,0,1,0,8.693,6.931,4.679,4.679,0,0,0,13.374,11.612Zm3.21,1.337h-.347a6.463,6.463,0,0,1-5.726,0h-.347A4.816,4.816,0,0,0,5.35,17.764v1.2a2.007,2.007,0,0,0,2.006,2.006H19.392A2.007,2.007,0,0,0,21.4,18.967v-1.2A4.816,4.816,0,0,0,16.584,12.949Zm-9.349-.56a2.667,2.667,0,0,0-1.885-.777H2.675A2.677,2.677,0,0,0,0,14.287v1.337a1.336,1.336,0,0,0,1.337,1.337H4.092A6.129,6.129,0,0,1,7.234,12.389Z" transform="translate(0 -2.25)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/email-logs"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.986" height="20.24" viewBox="0 0 26.986 20.24">
                         <path id="Icon_awesome-envelope" data-name="Icon awesome-envelope" d="M26.475,11.183a.317.317,0,0,1,.511.248V22.21a2.531,2.531,0,0,1-2.53,2.53H2.53A2.531,2.531,0,0,1,0,22.21V11.436a.315.315,0,0,1,.511-.248c1.181.917,2.746,2.082,8.122,5.988,1.112.812,2.989,2.519,4.86,2.509,1.882.016,3.795-1.729,4.865-2.509C23.734,13.271,25.294,12.1,26.475,11.183Zm-12.982,6.81c1.223.021,2.983-1.539,3.869-2.182,6.994-5.076,7.527-5.518,9.139-6.783a1.261,1.261,0,0,0,.485-1v-1a2.531,2.531,0,0,0-2.53-2.53H2.53A2.531,2.531,0,0,0,0,7.03v1a1.269,1.269,0,0,0,.485,1c1.613,1.26,2.145,1.708,9.139,6.783C10.51,16.454,12.27,18.014,13.493,17.993Z" transform="translate(0 -4.5)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                         <li><NavLink to="/checklists"><span><img src='/assets/images/checklist.svg' /></span>
                         </NavLink></li>
                     <li><NavLink to="/sop/1"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="21.733" viewBox="0 0 26.748 21.733">
                     <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M4.179,20.092H.836A.836.836,0,0,0,0,20.928v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V20.928A.836.836,0,0,0,4.179,20.092Zm0-16.717H.836A.836.836,0,0,0,0,4.211V7.554a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V4.211A.836.836,0,0,0,4.179,3.375Zm0,8.359H.836A.836.836,0,0,0,0,12.57v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V12.57A.836.836,0,0,0,4.179,11.734Zm21.733,9.195H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V21.764A.836.836,0,0,0,25.912,20.928Zm0-16.717H9.195a.836.836,0,0,0-.836.836V6.718a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V5.047A.836.836,0,0,0,25.912,4.211Zm0,8.359H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V13.405A.836.836,0,0,0,25.912,12.57Z" transform="translate(0 -3.375)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                         <li><NavLink to="/templates"><span><img src='/assets/images/Template.svg' /></span>
                         </NavLink></li>
                         <li><NavLink to="/projectStatus"><span><img src='/assets/images/ProjectStatus.svg' /></span>
</NavLink></li>
                         <li>
                 <a onClick={toggleSidebar} className="toggle-sidebar-btn">
                    <span>
                        {isSidebarOpen ? (
                           <i className="fa fa-times" style={{ color: 'white' }}></i>
                           
                        ) : (
                            <i className="fa fa-bars" style={{ color: 'white' }}></i>
                        )}
                    </span>
                    {/* {isSidebarOpen ? 'Hide Sidebar' : 'Show Sidebar'} */}
                </a>

                 </li>
                         </>
             )}

             {role === "team" && (
                 <>
                
                     {/* <li><NavLink to="/dashboard">
                         <span><svg xmlns="http://www.w3.org/2000/svg" width="20.624" height="16.041" viewBox="0 0 20.624 16.041">
                             <path id="Icon_awesome-tachometer-alt" data-name="Icon awesome-tachometer-alt" d="M10.312,2.25A10.312,10.312,0,0,0,1.4,17.747a1.133,1.133,0,0,0,.983.544H18.243a1.133,1.133,0,0,0,.983-.544,10.312,10.312,0,0,0-8.914-15.5Zm0,2.292a1.131,1.131,0,0,1,1.086.847,1.93,1.93,0,0,0-.124.239l-.33.991a1.116,1.116,0,0,1-.632.215,1.146,1.146,0,0,1,0-2.292ZM3.437,14.854a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,3.437,14.854ZM5.156,9.125A1.146,1.146,0,1,1,6.3,7.979,1.146,1.146,0,0,1,5.156,9.125Zm8.836-2.593L11.8,13.12A2.249,2.249,0,0,1,12.286,16H8.339a2.268,2.268,0,0,1,1.827-3.423l2.2-6.589a.859.859,0,1,1,1.63.544Zm.525,2.048.556-1.667a1.129,1.129,0,0,1,.4-.08,1.146,1.146,0,1,1,0,2.292,1.128,1.128,0,0,1-.951-.545Zm2.67,6.274a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,17.187,14.854Z" transform="translate(0 -2.25)" fill="#fff" />
                         </svg></span>
                         </NavLink></li> */}
                     <li><NavLink to="/clients"><span><svg xmlns="http://www.w3.org/2000/svg" width="21.127" height="24.145" viewBox="0 0 21.127 24.145">
                         <path id="Icon_awesome-user-tie" data-name="Icon awesome-user-tie" d="M10.564,12.073A6.036,6.036,0,1,0,4.527,6.036,6.036,6.036,0,0,0,10.564,12.073Zm4.518,1.537-2.254,9.026-1.509-6.414,1.509-2.641H8.3l1.509,2.641L8.3,22.636,6.046,13.61A6.327,6.327,0,0,0,0,19.92v1.962a2.264,2.264,0,0,0,2.264,2.264h16.6a2.264,2.264,0,0,0,2.264-2.264V19.92A6.327,6.327,0,0,0,15.081,13.61Z" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/progress"><span><svg xmlns="http://www.w3.org/2000/svg" width="14.337" height="16.385" viewBox="0 0 14.337 16.385">
                         <path id="Icon_awesome-percent" data-name="Icon awesome-percent" d="M3.584,7.168A3.584,3.584,0,1,0,0,3.584,3.582,3.582,0,0,0,3.584,7.168Zm0-5.12A1.536,1.536,0,1,1,2.048,3.584,1.536,1.536,0,0,1,3.584,2.048Zm7.168,7.168A3.584,3.584,0,1,0,14.337,12.8,3.582,3.582,0,0,0,10.752,9.216Zm0,5.12A1.536,1.536,0,1,1,12.289,12.8,1.536,1.536,0,0,1,10.752,14.337Zm1.8-14.33,1.011,0a.768.768,0,0,1,.63,1.21L2.477,16.052a.766.766,0,0,1-.627.326l-1.069,0a.768.768,0,0,1-.63-1.21L11.927.333A.766.766,0,0,1,12.554.006Z" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/timeTracking"><span><svg xmlns="http://www.w3.org/2000/svg" width="23.561" height="23.561" viewBox="0 0 23.561 23.561">
                         <path id="Icon_awesome-clock" data-name="Icon awesome-clock" d="M12.343.563A11.781,11.781,0,1,0,24.124,12.343,11.779,11.779,0,0,0,12.343.563Zm2.712,16.631-4.19-3.045a.574.574,0,0,1-.233-.461V5.693a.572.572,0,0,1,.57-.57h2.28a.572.572,0,0,1,.57.57v6.541l3.016,2.195a.57.57,0,0,1,.124.8l-1.34,1.843A.574.574,0,0,1,15.056,17.193Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/opportunities"><span><svg xmlns="http://www.w3.org/2000/svg" width="17.497" height="25.451" viewBox="0 0 17.497 25.451">
                         <path id="Icon_awesome-lightbulb" data-name="Icon awesome-lightbulb" d="M4.775,22.584a1.591,1.591,0,0,0,.266.879l.849,1.277a1.59,1.59,0,0,0,1.324.71h3.067a1.59,1.59,0,0,0,1.324-.71l.85-1.277a1.59,1.59,0,0,0,.266-.879l0-1.906H4.772ZM0,8.748A8.7,8.7,0,0,0,2.165,14.5a14.285,14.285,0,0,1,2.6,4.546c0,.013,0,.026.005.039h7.965c0-.013,0-.025.005-.039a14.285,14.285,0,0,1,2.6-4.546A8.747,8.747,0,1,0,0,8.748ZM8.748,4.772A3.981,3.981,0,0,0,4.772,8.748a.8.8,0,0,1-1.591,0A5.573,5.573,0,0,1,8.748,3.181a.8.8,0,0,1,0,1.591Z" transform="translate(0 0)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                     <li><NavLink to="/sop/1"><span><svg xmlns="http://www.w3.org/2000/svg" width="26.748" height="21.733" viewBox="0 0 26.748 21.733">
                     <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M4.179,20.092H.836A.836.836,0,0,0,0,20.928v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V20.928A.836.836,0,0,0,4.179,20.092Zm0-16.717H.836A.836.836,0,0,0,0,4.211V7.554a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V4.211A.836.836,0,0,0,4.179,3.375Zm0,8.359H.836A.836.836,0,0,0,0,12.57v3.343a.836.836,0,0,0,.836.836H4.179a.836.836,0,0,0,.836-.836V12.57A.836.836,0,0,0,4.179,11.734Zm21.733,9.195H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V21.764A.836.836,0,0,0,25.912,20.928Zm0-16.717H9.195a.836.836,0,0,0-.836.836V6.718a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V5.047A.836.836,0,0,0,25.912,4.211Zm0,8.359H9.195a.836.836,0,0,0-.836.836v1.672a.836.836,0,0,0,.836.836H25.912a.836.836,0,0,0,.836-.836V13.405A.836.836,0,0,0,25.912,12.57Z" transform="translate(0 -3.375)" fill="#fff" />
                     </svg></span>
                         </NavLink></li>
                         
                         <li>
                 <a onClick={toggleSidebar} className="toggle-sidebar-btn">
                    <span>
                        {isSidebarOpen ? (
                           <i className="fa fa-times" style={{ color: 'white' }}></i>
                           
                        ) : (
                            <i className="fa fa-bars" style={{ color: 'white' }}></i>
                        )}
                    </span>
                    {/* {isSidebarOpen ? 'Hide Sidebar' : 'Show Sidebar'} */}
                </a>

                 </li>
                     </>
             )}
         </ul>
     </div>
     </>

    )
}

export default LeftMenu