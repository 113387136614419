import React from 'react';
import { useLocation } from 'react-router-dom';
import {getFileDisplay, getFileDisplaydefault, getFileDisplaySearch} from '../utils/fileChecker';
const formatDate = (dateString) => {
    const fileDate = new Date(dateString);
    return fileDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // AM/PM format
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
};

function SearchDetails() {
    const location = useLocation();
    const { searchData = [], query = '' } = location.state || {}; // Default to empty array and empty string

    // Function to strip HTML tags from a string
    function stripHtmlTags(html) {
        if (!html) return '';
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    // Function to highlight the query in the text
    const highlightText = (text, query) => {
        if (!query) return text; // If no query, return text as is
        const regex = new RegExp(`(${query})`, 'gi'); // Case insensitive match of the query
        return text.split(regex).map((part, index) => 
            part.toLowerCase() === query.toLowerCase() ? 
            <span key={index} style={{ backgroundColor: '#ffc', color: '#000', textDecoration: 'underline' }}>{part}</span> : 
            part
        );
    };

    const searchedComments = searchData?.comments || [];
    const searchedFiles = searchData?.projects || [];

    return (
        <div className="main-dashboard-admin">
            <div className='search-main-outer'>
                <div className='search-heading'>
                    <h2>Searching for <span>{query}</span></h2>
                </div>
                <div className='search-outer-sec'>
                    {searchedComments.length > 0 ? (
                        searchedComments.map((item, index) => (
                            <div key={index} className="search-data">
                                <div className='search-data-inner'>
                                    <div className='search-date'>
                                        <span>{formatDate(item?.createdAt)}</span>
                                    </div>
                                    <div className='progress-client-details'>
                                        <div className="progress-client">
                                            <img src='/assets/images/progress-img.png' alt="Progress" />
                                        </div>
                                        <div className='progress-date'>
                                            <a href={`clients/client-detail/project-detail/discussions/discussion-detail/${item.discussion?.id}`}>
                                                Re: {item.discussion?.title}
                                            </a>
                                            <div className='text-pro'>
                                                {/* Highlighted Comment Text */}
                                                <p>{item.user?.firstName} - {highlightText(stripHtmlTags(item?.comment), query)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No search results found.</p>
                    )}
              
                </div>
                <div className='search-heading'>
                    <h2>Files</h2>
                </div>

                <div className='files-outer sr-cstm-file-display'>
                    <div className='files-data-inner'>
                        {
                            searchedFiles[0]?.files?.length > 0 ? (
                                searchedFiles[0]?.files?.map((file, index) => (
                                    <div className='file-grid' key={index}>
                                        <div className='file-image'>
                                            {getFileDisplaySearch(file.fileURL)}
                                        </div>
                                        <div className='files-infodata'>
                                            <p className='file-name'>{file.originalname}</p>
                                            <div className='file-meta'>
                                                <span>Added by {file.addedByUser.firstName} on {formatDate(file.createdAt)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : 'No File Found'
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SearchDetails;
