import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link, NavLink } from 'react-router-dom';
import API from '../../AxiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';

function AddTeam() {
  const initialFormData = {
    id: '',
    photo: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmpassword: '',
    mobileNumber: '',
    roleId: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [files, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  
  const dispatch = useDispatch();
  const title = id ? "Update Team Member" : "Add Team Member";

  useEffect(() => {
    dispatch(pageTitle(title));
    if (id) {
      getMemberDetail();
    }
  }, [id, dispatch]);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(file);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let newErrors = {};

    const { firstName, lastName, email, password, confirmpassword, roleId } = formData;

    if (!firstName.trim()) newErrors.firstName = 'First name is required';
    if (!lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!password.trim()) {
      newErrors.password = 'Password is required';
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(password)) {
      newErrors.password = 'Minimum eight characters, at least one letter, one number, and one special character';
    }
    if (!confirmpassword.trim()) {
      newErrors.confirmpassword = 'Confirm password is required';
    } else if (confirmpassword !== password) {
      newErrors.confirmpassword = 'Confirm password needs to be equal to the password';
    }
    if (!roleId) newErrors.roleId = 'User role is required';
    if (!files.name) {
      newErrors.photo = 'Photo is required';
    } else {
      const validExtensions = ['jpg', 'jpeg', 'png'];
      const extension = files.name.split('.').pop().toLowerCase();
      if (!validExtensions.includes(extension)) {
        newErrors.photo = 'Only JPG and PNG files are allowed';
      }
    }

    if (Object.keys(newErrors).length === 0) {
      const token = localStorage.getItem('token'); // Get token from local storage
      const data = new FormData();
      Object.keys(formData).forEach((field) => {
        data.append(field, formData[field]);
      });
      data.append('media', files);
      
      API.post('/admin/addTeam', data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Member added successfully");
          setFormData(initialFormData); // Reset to initial state
          navigate('/team');
        } else if (response.status === 203) {
          toast.error("Email Already Exists");
          setFormData(initialFormData);
          navigate('/team');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Member not added");
        setLoading(false);
      });
    } else {
      setErrors(newErrors);
      setLoading(false);
    }
  };

  const getMemberDetail = () => {
    API.get(`admin/memberDetail/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const member = response.data.data;
          setFormData({
            id: member.id,
            firstName: member.firstName,
            lastName: member.lastName,
            email: member.email,
            mobileNumber: member.mobileNumber,
            roleId: member.roleId,
            // Add any other fields you might need
          });
        } else {
          console.log('Error fetching member details');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="main-dashboard-admin sr-cstm-padding">
      {loading && (
        <div className='rotate-line-loader'>
          <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" />
        </div>
      )}

      <div className="form-add-member">
        <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}>
          <i className="fas fa-sign-out-alt"></i>
        </button>

        <div className="pro-img-tag" onClick={() => fileInputRef.current.click()} style={{ cursor: 'pointer' }}>
          {imagePreview ? (
            <>
              <img src={imagePreview} alt="Preview" style={{ maxWidth: '250px', height: 'auto' }} />
              <i className="far fa-edit"></i>
            </>
          ) : (
            <>
            <img src='https://t4.ftcdn.net/jpg/02/44/43/69/360_F_244436923_vkMe10KKKiw5bjhZeRDT05moxWcPpdmb.jpg' alt="Preview" style={{ maxWidth: '250px', height: 'auto' }} />
            <i className="far fa-edit"></i>
            </>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          {/* <div className="file file--upload"> */}
            <input
              type="file"
              name="media"
              id="input-file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handlePhotoChange}
              style={{ display: 'none' }} // Hidden input
            />
          {/* </div> */}
          {errors.photo && <p style={{ color: 'red' }}>{errors.photo}</p>}
          

          <div className="fields-row">
            <div className="single-field-form">
                <label>First Name:</label>
                <input type="text" name="firstName" maxLength={15} value={formData?.firstName} onChange={handleChange} />
                {errors.firstName && <p style={{ color: 'red' }}>{errors?.firstName}</p>}
            </div>
            <div className="single-field-form">
                  <label>Last Name:</label>
                 <input type="text" name="lastName" maxLength={15} value={formData?.lastName} onChange={handleChange} />
                 {errors.lastName && <p style={{ color: 'red' }}>{errors?.lastName}</p>}
            </div>
            <div className="single-field-form">
            <label>Email Address:</label>
               <input type="email" name="email" value={formData?.email} onChange={handleChange} />
               {errors.email && <p style={{ color: 'red' }}>{errors?.email}</p>}
            </div>
            <div className="single-field-form">
            <label>Password:</label>
               <input type="password" name="password" value={formData?.password} onChange={handleChange} />
               {errors.password && <p style={{ color: 'red' }}>{errors?.password}</p>}
            </div>
            <div className="single-field-form">
            <label>Confirm Password:</label>
               <input type="password" name="confirmpassword" value={formData?.confirmpassword} onChange={handleChange} />
               {errors.confirmpassword && <p style={{ color: 'red' }}>{errors?.confirmpassword}</p>}
            </div>
            <div className="single-field-form">
                <label>User Role:</label>
                <select name="roleId" onChange={handleChange}>
                    <option value="">Please Select</option>
                    <option value="1" selected={formData?.roleId=='1'}>Admin</option>
                    <option value="2" selected={formData?.roleId=='2'}>Manager</option>
                </select>
                {errors.roleId && <p style={{ color: 'red' }}>{errors?.roleId}</p>}
            </div>
          </div>

          <div className="form-btn-rows">
          <button className="global-icon-btn orange-btn" type="submit"><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> Save</button>
            <Link to={'/team'} className="global-icon-btn">
              <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
              Cancel
            </Link>
          </div>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' />

    </div>
  )
}

export default AddTeam
