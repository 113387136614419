import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../features/actions/authActions';
import UserLogin from './auth/UserLogin';

const Login = ({ handleLogin, isLoggedIn }) => {
  const [credentials, setCredentials] = useState({});
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [formValidation, setFormValidation] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/clients", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  const handleUserLogin = (token) => {
    handleLogin(token);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorsCopy = {};

    // Validate email
    const email = credentials.email || '';
    if (!email.trim()) {
      errorsCopy.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errorsCopy.email = 'Invalid Email';
    }

    // Validate password
    const password = credentials.password || '';
    if (!password.trim()) {
      errorsCopy.password = 'Password is required';
    }

    // Set errors and validation state
    setErrors(errorsCopy);

    // Only proceed if there are no errors
    if (Object.keys(errorsCopy).length === 0) {
      setFormValidation(true);
      // You might want to call your login function here
    } else {
      setFormValidation(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      {formValidation && (
        <UserLogin 
          credentials={credentials} 
          handleLogin={handleUserLogin} 
          setError={setError} 
        />
      )}
      <div className="login-page login">
        <div className="login-page-outer">
          <div className="logo-main-login">
            <img src="assets/images/logo-main.png" alt="Logo-Main" />
          </div>
          <div className="login-page-inner">
            <h1>Project Management - Login</h1>
            <form onSubmit={handleSubmit}>
              {error && <span className="form-error main-error">{error}</span>}
              <div className="single-field">
                <label>Email Address</label>
                <input 
                  type="text" 
                  placeholder="Email Address" 
                  name='email' 
                  value={credentials.email || ''} 
                  onChange={handleChange} 
                />
                {errors.email && <span className="form-error">{errors.email}</span>}
              </div>
              <div className="single-field">
                <label>Password</label>
                <div className="password-field">
                  <input 
                    type={passwordVisible ? 'text' : 'password'} 
                    placeholder="Password" 
                    name='password' 
                    value={credentials.password || ''} 
                    onChange={handleChange} 
                  />
                  <i 
                    className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`} 
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
                {errors.password && <span className="form-error">{errors.password}</span>}
              </div>
              <div className="single-field forget-password">
                <NavLink to='/forgot-password'>Forgot Password</NavLink>
              </div>
              <input type="submit" value="Login" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
