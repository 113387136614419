import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import Spinner from './Spinner'; // Import your spinner component or use an inline spinner

const LazyImageWithSpinner = ({ src, alt }) => {
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false); // Image has finished loading, hide the spinner
    };

    return (
        <div className="lazy-image-wrapper">
            {loading && <Spinner />}  {/* Show spinner while loading */}
            <LazyLoad height={50} offset={100} once>
                <img 
                    src={src} 
                    alt={alt} 
                    onLoad={handleImageLoad} 
                    style={{ display: loading ? 'none' : 'block' }} // Hide the image while it's loading
                />
            </LazyLoad>
        </div>
    );
};

export default LazyImageWithSpinner;
